import React from "react"
import "./App.css"
import MainPage from "./containers/MainPage/MainPage"


function App() {
    const version = process.env.REACT_APP_VERSION

    return <>
        <MainPage />
        <footer className="border-top d-flex align-items-center footer
                            justify-content-center mt-4">
            &copy; Copyright 2021 Ubiik Inc. {version}
        </footer>
    </>
}

export default App