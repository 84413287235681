import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom"
import React from "react"

import DownloadLoadprofile from "../Download/DownloadLoadprofile"
import Logout from "../../components/Logout/Logout"
import Meter from "./Meter"

import { LP_DOWNLOAD_PATH } from "../../globalVariables"

const External = props => {
    const properties = {
        langOptions: props.langOptions,
        langHandler: props.langHandler,
        logoutHandler: props.logoutHandler,
        nowLang: props.nowLang,
        translation: props.translation,
        userData: props.userData
    }

    return <BrowserRouter>
        <Switch>
            <Route exact path={LP_DOWNLOAD_PATH}>
                <DownloadLoadprofile />
            </Route>
            <Route exact path="/monitor">
                <Meter {...properties} />
            </Route>
            <Route exact path="/monitor/:meterId" render={props =>
                <Meter {...props} {...properties} /> } />
            <Route exact path="/Logout">
                <Logout logoutHandler={props.logoutHandler} />
            </Route>
            <Redirect to="/monitor" />
        </Switch>
    </BrowserRouter>
}

export default External