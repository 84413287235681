import React, { Component } from 'react';

class Logout extends Component{
    constructor(props){
        super(props);

        props.logoutHandler()
        window.location = "/";
    }

    componentDidMount () {
        this.props.logoutHandler()
    }

    render(){
        return (<div></div>);
    }
}

export default Logout;