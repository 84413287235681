import Axios from "axios"
import { Button, Modal } from "react-bootstrap"
import DataTable from "react-data-table-component"
import React, { Component } from "react"
import Select from "react-select"

import ErrorMessage from "../../../ErrorMessage"

import { EmailRule } from "../../../Utils"

class UserSettings extends Component {
    constructor(props) {
        super(props)

        this.apiUrlRoot = window.backendURL + "account"
        this.config = { headers: { Authorization: props.userData.authToken } }
        this.currentUserId = props.userData.id
        this.t = props.translation
        this.pageT = string => this.t("userManagement." + string)
        this.state = {
            confirmPassword: "",
            createError: null,
            email: "",
            getListError: null,
            isOpen: false,
            loading: false,
            name: "",
            pageIndex: 1,
            password: "",
            pageSize: 10,
            resetPage: true,
            role: "admin",
            sending: false,
            tableList: [],
            totalRows: 0
        }
    }

    componentDidMount() { this.getList(1, this.state.pageSize) }

    getList = (pageIndex, pageSize) => {
        this.setState({ loading: true, getListError: null })

        const apiUrl = this.apiUrlRoot + "?pageIndex=" + (pageIndex - 1)
            + "&pageSize=" + pageSize

        Axios.get(apiUrl, this.config)
            .then(response => {

                if (response.data) {
                    const { accounts, total } = response.data

                    this.setState({ tableList: accounts, totalRows: total })
                }
            })
            .catch(error => {
                if (error.response.status === 401) {
                    alert(this.pageT("noPermissionView"))
                }
                this.setState({ getListError: error })
            })
            .then(() => this.setState({ loading: false }))
    }

    handlePageChange = (pageIndex) => {
        this.setState({ pageIndex }, () => {
            this.getList(pageIndex, this.state.pageSize)
        })
    }

    handlePerRowsChange = (pageSize, pageIndex) => {
        this.setState({ pageSize, pageIndex }, () => {
            this.getList(pageIndex, pageSize)
        })
    }

    inputHandler = e => this.setState({ [e.target.id]: e.target.value })

    selectHandler = option => this.setState({ role: option.value })

    toggleModal = () => {
        this.setState({
            isOpen: !this.state.isOpen
        })
    }

    handleClose = () => {
        this.setState({
            isOpen: false
        })
    }

    handleShow = () => {
        this.setState({
            isOpen: true
        })
    }

    submitHandler = () => {
        const
            { email, name, password, role } = this.state,
            { pageT } = this,
            data = { birth: "1990-12-25T00:00:00Z", email, name, password, role }

        this.setState({ sending: true, createError: null })
        Axios.post(this.apiUrlRoot, data, this.config)
            .then(response => {
                this.getList(1, this.state.pageSize)
                this.setState({
                    confirmPassword: "",
                    email: "",
                    name: "",
                    pageIndex: 1,
                    password: "",
                    resetPage: !this.state.resetPage
                })
                alert(pageT("createdSuccessfully"))
            })
            .catch(error => {
                if (error.response.status === 401) {
                    alert(pageT("noPermissionCreate"))
                }
                this.setState({ createError: error })
            })
            .then(() => {
                this.setState({ sending: false })
                this.handleClose()
            })
    }

    deleteUserHandler(id) {
        const { pageT } = this

        if (window.confirm(pageT("confirmDelete"))) {
            const config
                = { headers: { Authorization: this.props.userData.authToken } }

            Axios.delete(window.backendURL + 'account/' + id, config)
                .then(response => {
                    if (response.status === 200) {
                        pageT("deletedSuccessfully")
                        this.getList(this.state.pageIndex, this.state.pageSize)
                    }
                })
        }
    }

    render() {
        const
            { confirmPassword, email, name, password, role, sending, tableList }
                = this.state,
            isEmailValid = EmailRule.test(email),
            isFormValid = confirmPassword
                && isEmailValid
                && name
                && password
                && confirmPassword === password,
            { pageT, t } = this,
            roleOptions = ["root", "admin", "monitor"]
                .map(r => ({ label: pageT(r), value: r })),
            showConfirmPWAlert = confirmPassword
                && password !== confirmPassword,
            tableColumns = [
                { name: this.pageT("account"), selector: "email" },
                { name: this.pageT("name"), selector: "name" },
                {
                    cell: row => this.pageT(row.role),
                    name: this.pageT("role"),
                    selector: "role",
                },
                {
                    cell: row =>
                        row.id === this.currentUserId
                            ? "-"
                            : <i
                                className="cursor-pointer material-icons txt-red"
                                onClick={this.deleteUserHandler.bind(this, row.id)}>
                                delete
                        </i>,
                    center: true,
                    name: this.pageT("delete")
                }
            ]

        return <div className="container-fluid">
            <ol className="breadcrumb">
                <li className="active">
                    <span>{t("system.userManagement")}</span>
                </li>
            </ol>
            <div className="clearfix" />
            <div className="text-right mt-10">
                <button
                    className="btn btn-primary btn-outline"
                    onClick={this.handleShow}>
                    {pageT("createAccount")}
                </button>
            </div>
            <Modal show={this.state.isOpen} onHide={this.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{pageT("createAccount")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <label className="control-label mb-1" htmlFor="email">
                        {pageT("account")}
                    </label>
                    <div className="mb-3">
                        <input
                            autoComplete="off"
                            className={"form-control"
                                + (!email || (email && !isEmailValid)
                                    ? " is-invalid"
                                    : "")}
                            id="email"
                            onChange={this.inputHandler}
                            type="text"
                            value={email} />
                        <div className={"invalid-feedback d-"
                            + (!email ? "block" : "none")}>
                            {pageT("alertEmail")}
                        </div>
                        <div className={"invalid-feedback d-"
                            + (email && !isEmailValid ? "block" : "none")}>
                            {pageT("alertEmailFormat")}
                        </div>
                    </div>
                    <div className="mb-3">
                        <label className="control-label mb-1" htmlFor="name">
                            {pageT("name")}
                        </label>
                        <input
                            autoComplete="off"
                            className={"form-control"
                                + (name ? "" : " is-invalid")}
                            id="name"
                            onChange={this.inputHandler}
                            type="text"
                            value={name} />
                        <div className={"invalid-feedback d-"
                            + (name ? "none" : "block")}>
                            {pageT("alertName")}
                        </div>
                    </div>
                    <label className="control-label mb-1" htmlFor="role">
                        {pageT("selectRole")}
                    </label>
                    <Select
                        id="role"
                        isSearchable={false}
                        onChange={this.selectHandler}
                        options={roleOptions}
                        value={{ label: pageT(role), value: role }} />
                    <div className="mb-3">
                        <label
                            className="control-label mb-1 mt-3"
                            htmlFor="password">
                            {pageT("password")}
                        </label>
                        <input
                            autoComplete="new-password"
                            className={"form-control"
                                + (password ? "" : " is-invalid")}
                            id="password"
                            onChange={this.inputHandler}
                            type="password"
                            value={password} />
                        <div className={"invalid-feedback d-"
                            + (password ? "none" : "block")}>
                            {pageT("alertPassword")}
                        </div>
                    </div>
                    <label
                        className="control-label mb-1"
                        htmlFor="confirmPassword">
                        {pageT("confirmPassword")}
                    </label>
                    <input
                        autoComplete="off"
                        className={"form-control"
                            + (showConfirmPWAlert ? " is-invalid" : "")}
                        id="confirmPassword"
                        onChange={this.inputHandler}
                        type="password"
                        value={confirmPassword} />
                    <div className={"invalid-feedback d-"
                        + (showConfirmPWAlert ? "block" : "none")}>
                        {pageT("confirmPasswordFail")}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.handleClose}>
                        {t("modal.close")}
                    </Button>
                    <Button
                        disabled={!isFormValid || sending}
                        onClick={this.submitHandler}
                        variant="primary">
                        {pageT(sending ? "sending" : "create")}
                    </Button>
                </Modal.Footer>
            </Modal>
            <ErrorMessage
                className="alert-danger alert text-center mt-3"
                data={this.state.getListError || this.state.createError} />
            <DataTable
                columns={tableColumns}
                data={tableList}
                noHeader={true}
                onChangePage={this.handlePageChange}
                onChangeRowsPerPage={this.handlePerRowsChange}
                pagination={true}
                paginationResetDefaultPage={this.state.resetPage}
                paginationServer={true}
                paginationTotalRows={this.state.totalRows}
                progressPending={this.state.loading}
            />
        </div>
    }
}

export default UserSettings