import React, { Component } from 'react';
import Axios from 'axios';
import Moment from 'react-moment';
import 'moment-timezone';

Moment.globalFormat = 'YYYY/MM/DD HH:mm';

class MeterDetail extends Component{
    state = {id: "", detail: {ID: ""}}

    componentDidMount () {
        const
            apiUrl = window.backendURL + "meter/" + this.props.match.params.id,
            config = {headers: {Authorization: this.props.userData.authToken}}

        Axios.get(apiUrl, config)
            .then(response => {
                if(response.status === 200){
                    if (response.data.meter) {
                        this.setState({detail: response.data.meter})
                    }
                }
            })
            .catch(error => console.error(error.response || error))
    }

    render(){
        const
            {detail} = this.state,
            {LowerVoltageThreshold, UpperVoltageThreshold} = detail,
            pageT = string => this.props.translation("meter." + string)

        return (
            <div className="container-fluid">
                <div className="row heading-bg">
                    <div className="col-12">
                    <ol className="breadcrumb">
                        <li><a href="/RakutenManagement/Meter/">{this.props.translation("system.meterManagement")}</a></li>
                        <li className="active"><span>{this.props.translation("meter.meterId")}:{this.state.detail.ID}</span></li>
                    </ol>
                    </div>
                </div>
                <div className="row mt-20">
                    <div className="col-12">
                    <div className="title-hr">
                        <h5 className="text-center">{this.props.translation("meter.detailTitle")}</h5>
                    </div><br />
                    <div className="text-center">{this.state.detail.ID}</div>
                    </div>
                    <div className="col-md-12 mt-20">
                    <div className="table-responsive">
                        <table className="table table-hover dataTable">
                        <tbody>
                            <tr>
                            <td style={{"width": "200px"}}><span>{this.props.translation("meter.meterId")}</span></td>
                            <td><span>{this.state.detail.ID}</span></td>
                            </tr>
                            <tr>
                            <td><span>{this.props.translation("meter.fanId")}</span></td>
                            <td><span>{this.state.detail.ParentID || "-"}</span></td>
                            </tr>
                            <tr>
                                <td>{pageT("phaseWireType")}</td>
                                <td>{detail.MeterType || " - "}</td>
                            </tr>
                            <tr>
                                <td>{pageT("voltage")}</td>
                                <td>{(LowerVoltageThreshold
                                        ? (LowerVoltageThreshold + "V, ")
                                        : "")
                                    + (UpperVoltageThreshold
                                        ? (UpperVoltageThreshold + "V")
                                        : "")}</td>
                            </tr>
                            <tr>
                                <td>{pageT("current")}</td>
                                <td>{detail.LoadCurrent
                                        ? (detail.LoadCurrent + "A")
                                        : "-"}</td>
                            </tr>
                            <tr>
                                <td>{pageT("ratedFrequency")}</td>
                                <td>{detail.FullDisplay
                                        ? detail.FullDisplay
                                        : "-"}</td>
                            </tr>
                            <tr>
                                <td>{pageT("firmwareVersion")}</td>
                                <td>{detail.FirmwareVersion
                                        ? detail.FirmwareVersion
                                        : "-"}</td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                    </div>
                </div>
                </div>
        );
    }
}

export default MeterDetail;