import React, {Component} from "react"

class DTPagination extends Component{
    
    state = {
        currentPage: this.props.currentPage,
        rowPerPage: 10
    };
   

    changeRowPerPageHandler = (event) => {
        this.props.onChangeRowsPerPage(event.target.value, this.state.currentPage);
    }

    
    changePageHandler = (type) => {
        const
            {currentPage} = this.state,
            {rowCount} = this.props
        
        if (type === 'next') {
            this.props.onChangePage(currentPage + 1, rowCount)
            this.setState({"currentPage": currentPage + 1})
        }
        else if (type === 'prev') {
            this.props.onChangePage(currentPage - 1, rowCount)
            this.setState({"currentPage": currentPage - 1})
        }
    }

    render(){
        const
            paginateT = (str, param) =>
                this.context.translation("dataTable." + str, param),
            options = [10, 25, 50, 100]
                        .map((num, i) => <option
                                            key={"perrow-" + i}
                                            value={num}>
                                            {paginateT("perRow", {num})}
                                        </option>),
            {currentPage, rowCount, rowsPerPage} = this.props,
            start = (currentPage - 1) * rowsPerPage + 1,
            end = (currentPage * rowsPerPage) > rowCount
                    ? rowCount
                    : (currentPage * rowsPerPage),
            prevBtnDisabled = currentPage - 1 <= 0,
            nextBtnDisabled = rowCount <= currentPage * rowsPerPage

        return <div className="align-items-center d-flex justify-content-between
                                w-100">
            <select
                className="form-control form-control-sm w-auto"
                value={rowsPerPage}
                onChange={this.changeRowPerPageHandler}>
                {options}
            </select>
            <div>{paginateT("display", {start, end, total: rowCount})}</div>
            <ul className="d-flex">
                <li className="page-item">
                    <button
                        className="page-link"
                        disabled={prevBtnDisabled}
                        onClick={this.changePageHandler.bind(this, "prev")}
                        tabIndex="-1">
                        <i className="material-icons">chevron_left</i>
                    </button>
                </li>
                <li className="page-item">
                    <button
                        className="page-link"
                        disabled={nextBtnDisabled}
                        onClick={this.changePageHandler.bind(this, "next")}
                        tabIndex="-1">
                        <i className="material-icons">chevron_right</i>
                    </button>
                </li>
            </ul>
        </div>
    }
}

export default DTPagination;