import React, { Component } from 'react';
import "./TopNav.css"

class TopNav extends Component{
    state = {};

    render(){
        const
            {langHandler, langOptions, nowLang} = this.props,
            langMenu = langOptions.map((option, i) => {
                const {label, value} = option

                let listItem = null

                if (nowLang.value !== value) {
                    listItem = <li key={"option-" + i}>
                        <button
                            className="px-3 py-1"
                            data-label={label}
                            data-value={value}
                            onClick={
                                langHandler.bind(this, option, {name: label})
                            }>
                            <i className="text-center zmdi zmdi-globe-alt"></i>
                            {nowLang.label}
                            <i className="mx-1 text-center zmdi
                                            zmdi-long-arrow-right"></i>
                            {label}
                        </button>
                    </li>
                }

                return listItem
            }),
            t = this.props.translation

        return (
            <nav className="navbar navbar-inverse fixed-top">
            <div className="mobile-only-brand">
                <div className="nav-header float-left">
                    <div className="logo-wrap">
                        <a href="/">
                            <img
                                alt="brand"
                                className="brand-img large"
                                src="/dist/img/fav/logo-full.png" />
                            <img
                                alt="brand"
                                className="brand-img small"
                                src="/dist/img/fav/logo-r.png" />
                            <span className="brand-text">
                                {this.props.translation('system.title')}
                            </span>
                        </a>
                    </div>
                </div>
            </div>
          <div className="mobile-only-nav" id="mobile_only_nav"><a className="toggle-left-nav-btn inline-block ml-20 float-left" id="toggle_nav_btn" href="/#"><i className="material-icons">menu</i></a>
          
                <ul className="nav navbar-right top-nav float-right">
                    
                    <li className="dropdown auth-drp">
                        <a
                            className="align-content-center d-flex pr-0"
                            data-toggle="dropdown"
                            href="/#">
                            {this.props.userData.name}
                            <span
                                className="material-icons"
                                style={{lineHeight: "inherit"}}>
                                arrow_drop_down
                            </span>
                        </a>
                        <ul
                            className="dropdown-menu user-auth-dropdown ws-nowrap" data-dropdown-in="fadeInRight"
                            data-dropdown-out="fadeOutDown"> 
                            {langMenu}
                            <li>
                                <a
                                    className="px-3 py-1"
                                    href="/#"
                                    onClick={this.props.logoutHandler}>
                                    <i className="text-center zmdi zmdi-power" />
                                    <span>{t("system.logout")}</span>
                                </a>
                            </li>
                        </ul>
                    </li>
                    </ul>
                </div>
            </nav>
        );
    }
}

export default TopNav;