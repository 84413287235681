import {BrowserRouter as Router, Switch, Route} from "react-router-dom"
import React, { Component } from "react"
import {Redirect} from "react-router-dom"

import Alert from "../../components/ContentPage/System/UserSettings/Alert"
import ConnectionAnalysis from
    "../../components/ContentPage/RakutenManagement/Realtime/ConnectionAnalysis"
import Dashboard from
    "../../components/ContentPage/RakutenManagement/Dashboard/Dashboard"
import DeviceStatus from
    "../../components/ContentPage/RakutenManagement/Realtime/DeviceStatus"
import DownloadLoadprofile from "../../components/Download/DownloadLoadprofile"
import DownloadPastLoadProfile
    from "../../components/ContentPage/RakutenManagement/DownloadPastLoadProfile"
import ExportLoadProfile
    from "../../components/ContentPage/RakutenManagement/LoadProfile/Export"
import ExportMeter
    from "../../components/ContentPage/RakutenManagement/ExportMeter"
import Installation
    from "../../components/ContentPage/System/UserSettings/Installation"
import LoadProfile from
    "../../components/ContentPage/RakutenManagement/LoadProfile/LoadProfile"
import Logout from "../../components/Logout/Logout"
import Meter from
    "../../components/ContentPage/RakutenManagement/Meter/Meter"
import MeterDetail from
    "../../components/ContentPage/RakutenManagement/Meter/Detail"
import Sidebar from "../Sidebar/Sidebar"
import SimplePage from "../../components/External/Meter"
import TopNav from "../TopNav/TopNav"
import UserSettings
    from "../../components/ContentPage/System/UserSettings/UserSettings"

import { LP_DOWNLOAD_PATH } from "../../globalVariables"

class ContentPage extends Component{
    render(){
        const
            defaultPagePaths = [
                "Alert",
                "ConnectionAnalysis",
                "Dashboard",
                "DeviceStatus",
                "DownloadPastLoadProfile",
                "Export",
                "ExportMeter",
                "Installation",
                "LoadProfile",
                "Meter",
                "Meter/Detail/:id",
                "UserManagement"
            ].map(p => "/RakutenManagement/" + p),
            {
                langHandler,
                langOptions,
                logoutHandler,
                nowLang,
                role,
                translation,
                userData
            } = this.props,
            t = translation,
            fullProperties = {
                langHandler,
                langOptions,
                logoutHandler,
                nowLang,
                translation,
                userData
            },
            simplePagePaths = ["/monitor", "/monitor/:meterId"],
            userDataT = {translation, userData}

        return <Router>
            <Switch>
                <Route exact path={LP_DOWNLOAD_PATH}>
                    <DownloadLoadprofile />
                </Route>
                <Route path={defaultPagePaths}>
                    <div className="wrapper theme-active">
                        <TopNav {...fullProperties} />
                        <Sidebar role={role} translation={t} />
                        <div className="page-wrapper">
                            <Route
                                exact
                                path="/RakutenManagement/ConnectionAnalysis">
                                <ConnectionAnalysis {...userDataT} />
                            </Route>
                            <Route exact path="/RakutenManagement/Dashboard">
                                <Dashboard {...userDataT} />
                            </Route>
                            <Route exact path="/RakutenManagement/DeviceStatus">
                                <DeviceStatus {...userDataT} />
                            </Route>
                            <Route exact path="/RakutenManagement/Export">
                                <ExportLoadProfile
                                    {...userDataT}
                                    nowLang={nowLang} />
                            </Route>
                            <Route exact path="/RakutenManagement/ExportMeter">
                                <ExportMeter {...userDataT} nowLang={nowLang} />
                            </Route>
                            <Route
                                exact
                                path="/RakutenManagement/LoadProfile"
                                render={props =>
                                <LoadProfile {...props} {...userDataT} /> } />
                            <Route
                                exact
                                path="/RakutenManagement/DownloadPastLoadProfile"
                                render={props =>
                                <DownloadPastLoadProfile {...props} {...userDataT} /> } />
                            <Route exact path="/RakutenManagement/Meter">
                                <Meter {...userDataT} />
                            </Route>
                            <Route
                                exact
                                path="/RakutenManagement/Meter/Detail/:id"
                                render={props =>
                                <MeterDetail {...props} {...userDataT} /> } />
                    {this.props.role === "root"
                        ? <>
                            <Route exact path="/RakutenManagement/Alert">
                                <Alert {...userDataT} />
                            </Route>
                            <Route exact path="/RakutenManagement/Installation">
                                <Installation {...userDataT} />
                            </Route>
                            <Route
                                exact
                                path="/RakutenManagement/UserManagement">
                                <UserSettings {...userDataT} />
                            </Route>
                        </>
                        : null}
                        </div>
                    </div>
                </Route>
                <Route path={simplePagePaths}>
                    <Route exact path="/monitor" render={() =>
                        <SimplePage {...fullProperties} />} />
                    <Route exact path="/monitor/:meterId" render={props =>
                        <SimplePage {...props} {...fullProperties} /> } />
                </Route>
                <Route exact path="/Logout" render={() =>
                    <Logout logoutHandler={logoutHandler} />} />
            {this.props.role === "monitor"
                ? <Redirect to="/monitor" />
                : <Redirect to="/RakutenManagement/Dashboard" />}
            </Switch>
        </Router>
    }
}

export default ContentPage