import React, { useEffect, useState } from "react"

import Axios from "axios"
import { useTranslation } from "react-multi-lang"

import ErrorMessage from "../ErrorMessage"
import Spinner from "../Spinner"

export default function DownloadLoadprofile() {
    const
        [downloaded, setDownloaded] = useState(false),
        [downloading, setDownloading] = useState(false),
        [error, setError] = useState(null)

    const
        searchParams = new URLSearchParams(window.location.search),
        filename = searchParams.get("filename"),
        token = searchParams.get("token")

    const download = (filename, token) => {
        if (!filename || !token) return

        const config = {
            headers: { Authorization: "Bearer " + token },
            method: "GET",
            responseType: "blob",
            url: `${window.backendURL}rakuten/lp-download/${filename}`
        }

        setDownloading(true)
        setError(null)
        Axios(config)
            .then(response => {
                const { data } = response

                if (data.size > 0) {
                    const link = document.createElement("a")

                    link.href = window.URL.createObjectURL(new Blob([data]))
                    link.setAttribute("download", filename)
                    link.click()
                }

                setDownloaded(true)
            })
            .catch(error => setError(error))
            .then(() => setDownloading(false))
    }

    useEffect(() => download(filename, token), [filename, token])

    const
        t = useTranslation(),
        pageT = string => t("downloadLoadprofile." + string)

    return (
        <div className="mt-4 text-center">
            <span className="text-primary">
                {downloaded && pageT("downloaded")}
                {downloading && (
                    <div className="d-inline-block align-items-center">
                        <Spinner className="align-middle mr-2" />
                        {pageT("downloading")}
                    </div>
                )}
            </span>
            <ErrorMessage className="mt-4 text-danger" data={error} />
        </div>
    )
}