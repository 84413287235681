import Axios from "axios"
import moment from "moment"
import React, {Component} from "react"

import ErrorMessage from "../../../ErrorMessage"

class Export extends Component {
    state = {
        endDate: "",
        errorData: null,
        isSubmitted: false,
        sending: false,
        startDate: ""
    }

    componentDidMount () {
        const
            dateT = string => this.props.translation("calendar." + string),
            script = document.createElement("script")

        script.innerHTML = `
            $("#endDate, #startDate").bootstrapMaterialDatePicker({
                cancelText: "${dateT("cancelText")}",
                lang: "${dateT("lang")}",
                maxDate: moment(),
                minDate: moment().subtract(1, "y"),
                nowButton: true,
                nowText: "${dateT("nowText")}",
                okText: "${dateT("okText")}",
                time: false
            })
            $("#startDate").on("change", function(e, date) {
                const
                    endDate = moment($("#endDate").val()),
                    value = $(this).val(),
                    startDate = moment(value),
                    today = moment(),
                    oneMonthAfter = moment(value).add(30, "d"),
                    maxDate = oneMonthAfter > today ? today : oneMonthAfter

                $("#endDate").bootstrapMaterialDatePicker("setMinDate", value)
                $("#endDate").bootstrapMaterialDatePicker("setMaxDate", maxDate)
                setDateState($(this).val(), "startDate")
                setIsSubmitted(false)

                if (endDate > maxDate || endDate < startDate) {
                    $("#endDate").val(maxDate.format("YYYY-MM-DD"))
                    setDateState(maxDate.format("YYYY-MM-DD"), "endDate")
                }
            })
            $("#endDate").on("change", function(e, date) {
                setDateState($(this).val(), "endDate")
                setIsSubmitted(false)
            })
        `
        window.setDateState = this.setDateState
        window.setIsSubmitted = value => this.setState({isSubmitted: value})

        document.body.appendChild(script)
    }

    setDateState = (date, id) => this.setState({[id]: date})

    downloadLoadprofile = () => {
        const config = {
            data: {
                endDt: moment(this.state.endDate).add(1, "days").toISOString(),
                email: true,
                meterIDs: [],
                startDt: moment(this.state.startDate).toISOString()
            },
            headers: {Authorization: this.props.userData.authToken},
            method: "POST",
            url: window.backendURL + "rakuten/lp-export"
        }

        this.setState({ sending: false })
        Axios(config)
            .then(() => this.setState({ isSubmitted: true }))
            .catch(error => {
                if (error.response?.status === 403) {
                    alert(this.props.translation("export.busy"))
                }
                else {
                    this.setState({ errorData: error })
                }
            })
            .then(() => this.setState({ sending: false }))
    }

    render () {
        const
            {endDate, sending, startDate} = this.state,
            spinner =
                <div className="spinner-border spinner-border-sm" role="status">
                    <span className="sr-only">Loading...</span>
                </div>,
            t = this.props.translation,
            pageT = string => t("export." + string)

        return <div className="container-fluid">
            <ol className="breadcrumb mb-4">
                <li>{t("system.meterManageMenu")}</li>
                <li className="active">{t("system.exportLoadProfile")}</li>
            </ol>
            <div className="clearfix" />
            <div className="row">
                <div className="col-lg-6">
                    <div className="card-view panel text-center">
                        <div className="panel-heading">
                            <i className="material-icons md-36 txt-grey">
                                cloud_download
                            </i>
                            <h6 className="panel-title">
                                {pageT("loadprofile")}
                            </h6>
                            <hr className="my-3" />
                        </div>
                        <div className="panel-body p-0">
                            <div className="alert alert-warning">
                                {pageT("durationLimits")}
                            </div>
                            <input
                                className="form-control mb-3"
                                disabled={sending}
                                id="startDate"
                                placeholder={t("system.chooseStartDate")}
                                type="text" />
                            <input
                                className="form-control"
                                disabled={sending || !startDate}
                                id="endDate"
                                placeholder={t("system.chooseEndDate")}
                                type="text" />
                        </div>
                        <div className="mt-3 panel-footer pt-3">
                            <button
                                className="btn btn-primary"
                                disabled={sending || !endDate || !startDate}
                                onClick={this.downloadLoadprofile}>
                                {sending ? spinner : pageT("getCSVFile")}
                            </button>
                            <ErrorMessage
                                className="mt-3 text-center text-danger"
                                data={this.state.errorData} />
                        {this.state.isSubmitted
                            ? <div className="alert alert-success mb-0 mt-4">
                                {pageT("submittedMsg")}
                            </div>
                            : null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}

export default Export