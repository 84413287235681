import {BrowserRouter as Router, Redirect, Route, Switch}
    from "react-router-dom"
import React, { Component } from "react"

import DownloadLoadprofile from "../../components/Download/DownloadLoadprofile"
import ForgotPassword from "../../components/ForgotPassword/ForgotPassword"
import LoginPage from "../../components/LoginPage/LoginPage"
import ResetPassword from "../../components/ResetPassword/ResetPassword"

import { LP_DOWNLOAD_PATH } from "../../globalVariables"

class LandingPage extends Component{
    render(){
        return <Router>
            <Switch>
                <Route exact path="/ForgotPassword">
                    <ForgotPassword
                        defaultLang={this.props.defaultLang}
                        t={this.props.translation} />
                </Route>
                <Route exact path="/handle-reset-link" render={props =>
                    <ResetPassword
                        {...props}
                        defaultLang={this.props.defaultLang}
                        t={this.props.translation} />} />
                <Route exact path={LP_DOWNLOAD_PATH}>
                    <DownloadLoadprofile />
                </Route>
                <Redirect from="/Logout" to="/" />
                <Route path="/">
                    <LoginPage
                        defaultLang={this.props.defaultLang}
                        langHandler={this.props.langHandler}
                        langOptions={this.props.langOptions}
                        translation={this.props.translation}
                        userDataHandler={this.props.userDataHandler} />
                </Route>
            </Switch>
        </Router>
    }

}

export default LandingPage;