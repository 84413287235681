import Axios from "axios"
import React, {useEffect, useState} from "react"

import ErrorMessage from "../../../ErrorMessage"
import LeafletMap from "../../../LeafletMap"

export default function Installation (props) {
    const
        apiHeaders = {headers: {Authorization: props.userData.authToken}},
        baseUrl =  window.backendURL + "rakuten/",
        countUrl = baseUrl + "config/installed-count",
        updateMetersUrl = baseUrl + "meters/lat-lng"

    const
        [count, setCount] = useState(0),
        [countDefault, setCountDefault] = useState(0),
        [countLoadError, setCountLoadError] = useState(null),
        [countLoading, setCountLoading] = useState(true),
        [countSendError, setCountSendError] = useState(null),
        [countSending, setCountSending] = useState(false),
        [countChanged, setCountChanged] = useState(false),
        [file, setFile] = useState(null),
        [fileLoadError, setFileLoadError] = useState(false),
        [fileLoading, setFileLoading] = useState(false),
        [fileSendError, setFileSendError] = useState(false),
        [fileSending, setFileSending] = useState(false),
        [fileSent, setFileSent] = useState(false),
        [fileShowInvalid, setFileShowInvalid] = useState(false),
        [fileValidating, setFileValidating] = useState(false),
        [meters, setMeters] = useState([]),
        [metersLoading, setMetersLoading] = useState(false),
        [metersError, setMetersError] = useState(null)

    const
        downloadFile = () => {
            const config = {
                method: "GET",
                responseType: "blob",
                url: updateMetersUrl,
                ...apiHeaders
            }

            setFileLoading(true)
            Axios(config)
                .then(response => {
                    const
                        url = window.URL.createObjectURL(
                            new Blob([response.data])
                        ),
                        link = document.createElement("a")

                    link.href = url
                    link.setAttribute("download", "meters-lat-lng.zip")
                    document.body.appendChild(link)
                    link.click()
                })
                .catch(error => setFileLoadError(error))
                .then(() => setFileLoading(false))
        },
        getMeters = () => {
            setMetersLoading(true)
            Axios.post(window.backendURL + "meters", {}, apiHeaders)
                .then(response => setMeters(response.data.meters || []))
                .catch(error => setMetersError(error))
                .then(() => setMetersLoading(false))
        },
        selectFile = async e => {
            setFileSent(false)

            const
                file = e.target.files[0],
                form = e.target.form,
                isValid = file ? await validateFile(file) : false

            if (isValid) {
                setFile(file)
            }
            else {
                form.reset()
                setFile(null)
                setFileShowInvalid(true)
            }
        },
        sendCount = () => {
            setCountSending(true)
            Axios.put(countUrl, {count: parseInt(count)}, apiHeaders)
                .then(() => setCountDefault(count))
                .catch(error => setCountSendError(error))
                .then(() => setCountSending(false))
        },
        sendFile = e => {
            const data = new FormData(), form = e.target.form

            data.append("file", file)
            setFileSending(true)
            Axios.put(updateMetersUrl, data, apiHeaders)
                .then(() => {
                    form.reset()
                    getMeters()
                    setFile(null)
                    setFileSent(true)
                })
                .catch(error => setFileSendError(error))
                .then(() => setFileSending(false))
        },
        validateFile = async file => {
            setFileValidating(true)

            let result = false

            await file.text().then(text =>
                result = text.split(/\r|\n/)[0] === "meter_id,lat,lng"
            )

            setFileValidating(false)

            return result
        }

    useEffect(() => {
        getMeters()
        Axios.get(countUrl, apiHeaders)
            .then(response => {
                setCount(response.data.count)
                setCountDefault(response.data.count)
            })
            .catch(error => setCountLoadError(error))
            .then(() => setCountLoading(false))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const
        coordinates = meters.length > 0
            ? meters.filter(meter => meter.Lat && meter.Lng)
                .map(meter => ({id: meter.ID, lat: meter.Lat, lng: meter.Lng}))
            : [],
        countInvalid = !Math.sign(count) || Math.sign(count) === -1,
        countBtnDisabled
            = countLoading || countSending || !countChanged || countInvalid,
        t = props.translation,
        pageT = string => t("installation." + string)

    return <div className="container-fluid">
        <ol className="breadcrumb">
            <li>{t("system.settings")}</li>
            <li className="active">{t("system.installation")}</li>
        </ol>
        <div className="clearfix" />
        <ul className="nav nav-justified nav-tabs mb-4">
            <li className="nav-item">
                <a className="nav-link" href="/RakutenManagement/Alert">
                    {t("system.alertNotification")}
                </a>
            </li>
            <li className="nav-item active">
                <a className="nav-link" href="/RakutenManagement/Installation">
                    {t("system.installation")}
                </a>
            </li>
        </ul>
        <div
            className="align-items-center"
            style={{
                display: "inline-grid",
                gap: ".5rem",
                gridTemplateColumns: "repeat(4, auto)"
            }}>
            <label htmlFor="setCount">{pageT("setCount")}</label>
            <input
                className="form-control"
                disabled={countLoading || countSending}
                id="setCount"
                onChange={e => {
                    const num = e.target.value

                    setCount(num)
                    setCountChanged(parseInt(num) !== countDefault)
                    setCountSendError(null)
                }}
                style={{width: "8em"}}
                type="text"
                value={countChanged ? count : countDefault} />
            <button
                className="btn btn-primary"
                disabled={countBtnDisabled}
                onClick={sendCount}>
                {pageT(countSending ? "sending" : "submitCount")}
            </button>
            <ErrorMessage className="text-danger" data={countSendError} />
            {countLoadError
                ? <small
                    className="d-block pl-0 text-danger"
                    style={{gridColumn: "2 / -1"}}>
                    {pageT("countLoadError")}
                </small>
                : null}
        </div>
        <hr className="my-4" />
        <form
            className="align-items-center mb-3"
            style={{
                display: "inline-grid",
                gap: ".25rem .5rem",
                gridTemplateColumns: "repeat(4, auto)"
            }}>
            <label>{pageT("importCSV")}</label>
            <div className="custom-file" style={{width: "20em"}}>
                <input
                    accept=".csv"
                    className="custom-file-input"
                    id="csv"
                    onChange={selectFile}
                    onFocus={() => setFileShowInvalid(false)}
                    type="file" />
                <label
                    className="custom-file-label justify-content-start"
                    htmlFor="csv">
                    {file ? file.name : pageT("noFile")}
                </label>
            </div>
            <button
                className="btn btn-primary"
                onClick={sendFile}
                disabled={!file || fileSending || fileShowInvalid}
                type="button">
                {pageT(fileSending ? "importing" : "import")}
            </button>
            {fileSent
                ? <div className="pl-0 text-success">
                    {pageT("importedSuccessfully")}
                </div>
                : null}
            {fileValidating
                ? <small
                    className="pl-0 text-warning"
                    style={{gridColumn: "2 / -1"}}>
                    {pageT("validating")}
                </small>
                : null}
            {fileShowInvalid
                ? <div className="text-danger" style={{gridColumn: "2 / -1"}}>
                    {pageT("csvInvalid")}
                </div>
                : null}
        </form>
        <ErrorMessage className="ml-2 text-danger" data={fileSendError} />
        <div className="panel card-view">
            <div className="panel-body">
                <p>{pageT("downloadFileDesc")}</p>
                <p className="text-danger">{pageT("fileHint")}</p>
                <button
                    className="btn btn-primary mt-2"
                    disabled={fileLoading}
                    onClick={downloadFile}>
                    {pageT(fileLoading ? "downloading" : "download")}
                </button>
                <ErrorMessage
                    className="mt-2 text-danger"
                    data={fileLoadError} />
            </div>
        </div>
        <hr className="my-4" />
        <div className="panel card-view">
            <div className="panel-body" style={{height: 500}}>
            <ErrorMessage className="text-danger" data={metersError} />
            {metersLoading
                ? <div
                    className="h-100 w-100"
                    style={{display: "grid", placeItems: "center"}}>
                    {pageT("downloading")}
                </div>
                : <LeafletMap className="h-100" coordinates={coordinates} />}
            </div>
        </div>
    </div>
}