import Axios from "axios"
import {Helmet} from "react-helmet"
import moment from "moment"
import React, { Component } from "react"

import ErrorMessage from "../../../ErrorMessage"
import Spinner from "../../../Spinner"

class ConnectionAnalysis extends Component{
    state = {
        actualMeters: 0,
        barChartData: [0,0,0,0,0],
        barChartLabels: '[" ", " ", " ", " ", " ",]',
        connectionsError: null,
        countError: null,
        countLoading: false,
        totalMeters: 0
    }

    componentDidMount(){
        const
            baseUrl = window.backendURL + "rakuten/",
            activeUrl = baseUrl + "online?q=daily&limit=4",
            countUrl = baseUrl + "config/installed-count",
            headers = {headers: {Authorization: this.props.userData.authToken}}

        // Get Active Meters
        Axios.get(activeUrl, headers)
            .then(response => {
                if (!response.data?.data?.length) {
                    this.setState({
                        actualMeters: 0,
                        barChartData: [],
                        barChartLabels: "[]"
                    })
                    return
                }

                let count = 0, dates = [], index = [], meters = []

                count = response.data.data[0]?.count || 0

                response.data.data.forEach(item => {
                    const time = moment(item.ts)

                    index.push({
                        count: item.count,
                        time: time.format("YYYY/MM/DD"),
                        timestamp: time.unix()
                    })
                })
                index.sort((a, b) => a.timestamp - b.timestamp)
                index.forEach(item => {
                    meters.push(item.count)
                    dates.push(item.time)
                })
                this.setState({
                    actualMeters: count,
                    barChartData: meters,
                    barChartLabels: JSON.stringify(dates)
                })
            })
            .catch(error => this.setState({connectionsError: error}))

        // Get Total Count
        this.setState({countLoading: true})
        Axios.get(countUrl, headers)
            .then(response =>
                this.setState({totalMeters: response.data.count || 0}))
            .catch(error => this.setState({countError: error}))
            .then(() => this.setState({countLoading: false}))
    }

    componentWillUnmount () {
        if (window.barChart) {
            window.barChart.destroy()
        }
        if (window.doughnutChart) {
            window.doughnutChart.destroy()
        }
    }

    render(){
        const
            {
                actualMeters,
                barChartData,
                barChartLabels,
                countLoading,
                totalMeters
            } = this.state,
            notConnected
                = totalMeters < actualMeters ? 0 : totalMeters - actualMeters,
            spinner = <Spinner fontSize={16} />,
            t = this.props.translation

        return <div className="container-fluid">
            <ol className="breadcrumb mb-4">
                <li><a href="/#">{t("system.realtime")}</a></li>
                <li className="active">
                    <span>{t("system.connectionAnalysis")}</span>
                </li>
            </ol>
            <div className="clearfix" />
            <ErrorMessage
                className="alert alert-danger text-center"
                data={this.state.countError} />
            <div className="row">
                <div className="col-lg-4 col-12">
                    <div className="panel panel-success card-view">
                        <div className="c-state">
                            <h1>{countLoading ? spinner : totalMeters}</h1>
                            <hr className="row border-top-2" />
                            {t("meter.installedMeters")}
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-12">
                    <div className="panel panel-primary card-view">
                        <div className="c-state">
                            <h1>{countLoading ? spinner : actualMeters}</h1>
                            <hr className="row border-top-2" />
                            {t("meter.connectedMeters")}
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-12">
                    <div className="panel panel-danger card-view">
                        <div className="c-state">
                            <h1>{countLoading ? spinner : notConnected}</h1>
                            <hr className="row border-top-2" />
                            {t("meter.notConnectedMeters")}
                        </div>
                    </div>
                </div>
            </div>
            <ErrorMessage
                className="alert alert-danger text-center"
                data={this.state.connectionsError} />
            <div className="row">
                <div className="col-lg-6">
                    <div className="panel card-view">
                        <div className="panel-wrapper">
                            <div className="panel-body">
                                <canvas id="chart_2" height="200" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="panel card-view">
                        <div className="panel-wrapper">
                            <div className="panel-body">
                                <canvas id="chart_3" height="200" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Helmet><script>{`
                $( document ).ready(function() {
                    "use strict"

                    if ($("#chart_2")) {
                        if (window.doughnutChart === undefined) {
                            var ctx7 = document.getElementById("chart_2")
                                        .getContext("2d")
                            var data7 = {
                                labels: [
                                    "${t("meter.connectedMeters")}",
                                    "${t("meter.notConnectedMeters")}"
                                ],
                                datasets: [{
                                    data: [${actualMeters}, ${notConnected}],
                                    backgroundColor: [
                                        "rgba(255, 0, 140,.8)",
                                        "rgba(245, 184, 72, .8)",
                                    ],
                                    hoverBackgroundColor: [
                                        "rgba(255, 0, 140,1)",
                                        "rgba(245, 184, 72, 1)",
                                    ]
                                }]
                            }

                            var doughnutChart = new Chart(ctx7, {
                                type: 'doughnut',
                                data: data7,
                                options: {
                                    height: "300px",
                                    animation: {duration: 3000},
                                    responsive: true,
                                    legend: {
                                        labels: {
                                            fontFamily: "Roboto",
                                            fontColor:"#878787"
                                        }
                                    },
                                    tooltip: {
                                        backgroundColor:'rgba(33,33,33,1)',
                                        cornerRadius:0,
                                        footerFontFamily:"'Roboto'"
                                    },
                                    elements: {arc: {borderWidth: 0}},
                                    maintainAspectRatio: true
                                }
                            })

                            window.doughnutChart = doughnutChart
                        }
                        else {
                            const
                                doughnutData = window.doughnutChart.data
                                                .datasets[0].data,
                                doughnutDataLength = doughnutData.length,
                                newDData = [${actualMeters}, ${notConnected}],
                                isDDiff = newDData.some((v, i) =>
                                                        v !== doughnutData[i])

                            if (isDDiff) {
                                for (let i = 0; i < doughnutDataLength; ++i) {
                                    doughnutData.pop()
                                }
                                for (let i = 0; i < newDData.length; ++i) {
                                    doughnutData.push(newDData[i])
                                }
                            }

                            window.doughnutChart.data.labels = [
                                "${t("meter.connectedMeters")}",
                                "${t("meter.notConnectedMeters")}"
                            ]

                            window.doughnutChart.update()
                        }
                    }

                    if ($("#chart_3")) {
                        if (window.barChart === undefined) {
                            var ctx2 = document.getElementById("chart_3")
                                        .getContext("2d")
                            var data2 = {
                                labels: ${barChartLabels.length === 0
                                            ? "[]"
                                            : barChartLabels},
                                datasets: [{
                                    label: "${t("meter.connectedDailyMeters")}",
                                    backgroundColor: "rgba(255, 0, 140,.8)",
                                    borderColor: "rgba(255, 0, 140,.8)",
                                    data: [${barChartData}]
                                }]
                            }

                            var Bar = new Chart(ctx2, {
                                type:"bar",
                                data:data2,
                                options: {
                                    tooltips: {mode:"label"},
                                    scales: {
                                        yAxes: [{
                                            stacked: true,
                                            gridLines: {color: "#878787"},
                                            ticks: {
                                                beginAtZero: true,
                                                fontFamily: "Roboto",
                                                fontColor:"#878787",
                                                stepSize: 1
                                            }
                                        }],
                                        xAxes: [{
                                            stacked: true,
                                            gridLines: {color: "#878787"},
                                            ticks: {
                                                fontFamily: "Roboto",
                                                fontColor:"#878787"
                                            }
                                        }]
                                    },
                                    elements:{point: {hitRadius:40}},
                                    animation: {duration:3000},
                                    responsive: true,
                                    legend: {
                                        labels: {
                                            fontFamily: "Roboto",
                                            fontColor:"#878787"
                                        }
                                    },
                                    tooltip: {
                                        backgroundColor: "rgba(33,33,33,1)",
                                        cornerRadius: 0,
                                        footerFontFamily: "'Roboto'"
                                    }
                                }
                            })

                            window.barChart = Bar
                        }
                        else {
                            const
                                barData
                                    = window.barChart.data.datasets[0].data,
                                barDataLength = barData.length,
                                newBData = [${barChartData}],
                                barLabels
                                    = window.barChart.data.labels,
                                barLabelLength = barLabels.length,
                                newBLabels = ${barChartLabels.length === 0
                                                ? "[]"
                                                : barChartLabels},
                                isBDiff = newBData.filter((v, i) =>
                                                v !== barData[i]).length > 0

                            if (isBDiff) {
                                for (let i = 0; i < barDataLength; ++i) {
                                    barData.pop()
                                    barLabels.pop()
                                }
                                for (let i = 0; i < newBData.length; ++i) {
                                    barData.push(newBData[i])
                                    barLabels.push(newBLabels[i])
                                }
                            }

                            window.barChart.data.datasets[0].label
                                = "${t("meter.connectedDailyMeters")}"

                            window.barChart.update()
                        }
                    }
                })
            `}</script></Helmet>
        </div>
    }
}

export default ConnectionAnalysis