import {latLngBounds} from "leaflet"
import "leaflet/dist/leaflet.css"
import {Map, Marker, Popup, TileLayer} from "react-leaflet"
import React from "react"

export default function LeafletMap(props) {
    const {coordinates} = props

    let bounds = null

    if (coordinates.length > 0) {
        bounds = latLngBounds([coordinates[0], coordinates[0]])

        coordinates.forEach(coord => bounds.extend(coord))
    }
    else {
        // Japan Boundary
        bounds = latLngBounds([
            [31.0295791692, 129.408463169], [45.5514834662, 145.543137242]
        ])
    }

    return <Map
            bounds={bounds}
            className={props.className}
            onresize={e => e.target.fitBounds(bounds)}
            style={props.style}>
        <TileLayer
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
    {coordinates.map((coord, i) =>
        <Marker key={`marker-${i}`} position={coord}>
        {coord.id
            ? <Popup><span style={{fontSize: ".8rem"}}>{coord.id}</span></Popup>
            : null}
        </Marker>)}
    </Map>
}