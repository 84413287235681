import React from "react"
import { useTranslation } from "react-multi-lang"

const connectionStatus = time => {
    if (!time || isNaN(time)) return false

    // One heartbeat record every 5 minutes
    const heartBeatInterval = 1000 * 60 * 6

    return new Date().getTime() - time < heartBeatInterval
}

function MeterConnectionStatus(props) {
    const
        status = connectionStatus(props.lastTime),
        iconClasses = "material-icons mr-1 text-"
            + (status ? "success" : "danger"),
        text = status ? "connected" : "disconnected",
        t = useTranslation()

    return <>
        <i className={iconClasses}>
            {status ? "check_circle_outline" : "highlight_off"}
        </i>
        {t("meter.status." + text)}
    </>
}

export default MeterConnectionStatus