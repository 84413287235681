import React from "react"
import Items from './Items/Items';
import {createBrowserHistory} from "history"
import "./MenuItems.css"

const {pathname} = createBrowserHistory().location

function MenuItems(props){
    const
        isRealtime = ["/DeviceStatus", "/ConnectionAnalysis"]
                            .filter(v => pathname.includes(v)).length > 0,
        isMeterManage = ["/LoadProfile", "/Meter", "/Export"]
                            .filter(v => pathname.includes(v)).length > 0,
        t = props.translation

    return <ul className="nav navbar-nav side-nav nicescroll-bar">
        <Items
            level="1"
            materialIcons="dashboard"target="/RakutenManagement/Dashboard"
            title={t("system.dashboard")} />
        <li className="mt-1">
            <a
                aria-expanded={true}
                className={isRealtime ? "active" : ""}
                data-target="#realtime"
                data-toggle="collapse"
                href="/#" >
                <div className="float-left">
                    <i className="material-icons">insert_chart_outlined</i>
                    <span className="right-nav-text">
                        {t("system.realtime")}
                    </span>
                </div>
                <div className="float-right">
                    <i className="material-icons">arrow_drop_down</i>
                </div>
                <div className="clearfix"></div>
            </a>
            <ul
                className="collapse collapse-level-1 two-col-list show"
                id="realtime">
                <Items
                    level="2"
                    target="#"
                    title={t("system.powerOutageInfo")} />
                <Items
                    level="2"
                    target="#"
                    title={t("system.powerOutageHistory")} />
                <Items
                    level="2"
                    target="#"
                    title={t("system.serverStatus")} />
                <Items
                    level="2"
                    target="/RakutenManagement/DeviceStatus"
                    title={t("system.meterConnection")} />
                <Items
                    level="2"
                    target="/RakutenManagement/ConnectionAnalysis"
                    title={t("system.connectionAnalysis")} />
            </ul>
        </li>
        <li className="mt-1">
            <a
                aria-expanded={isMeterManage}
                className={isMeterManage ? "active" : ""}
                data-toggle="collapse"
                data-target="#meterManage"
                href="/#">
                <div className="float-left">
                    <i className="material-icons">power</i>
                    <span className="right-nav-text">
                        {t("system.meterManageMenu")}
                    </span>
                </div>
                <div className="float-right">
                    <i className="material-icons">arrow_drop_down</i>
                </div>
                <div className="clearfix"></div>
            </a>
            <ul
                className={"collapse collapse-level-1 two-col-list"
                            + (isMeterManage ? " show" : "")}
                id="meterManage">
                <Items
                    level="2"
                    subPages={["/LoadProfile", "/Meter/Detail/"]}
                    target="/RakutenManagement/Meter"
                    title={t("system.meterManagement")} />
                <Items
                    level="2"
                    target="/RakutenManagement/Export"
                    title={t("system.exportLoadProfile")} />
                <Items
                    level="2"
                    target="/RakutenManagement/DownloadPastLoadProfile"
                    title={t("system.downloadPastLoadProfile")}
                />
                <Items
                    level="2"
                    target="/RakutenManagement/ExportMeter"
                    title={t("system.exportMeter")} />
            </ul>
        </li>
        {props.role === "root"
            ? <Items
                className="mt-1"
                level="1"
                materialIcons="settings"
                subPages={["/Installation"]}
                target="/RakutenManagement/Alert"
                title={t("system.settings")} />
            : null}
        {props.role === "root"
            ? <Items
                className="mt-1"
                level="1"
                materialIcons="people"
                target="/RakutenManagement/UserManagement"
                title={t("system.userManagement")} />
            : null}
    </ul>
}

export default MenuItems;
