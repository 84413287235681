import React, { Component } from 'react';
import Axios from 'axios';

class ResetPassword extends Component{
    state = {
        errorData: {},
        isPasswordMatch: false,
        isPasswordReset: false,
        password: "",
        passwordAgain: "",
        sending: false,
        showNoMatch: false
    }

    inputHandler = e => this.setState(
        {[e.target.id]: e.target.value}, this.validation
    )

    submitHandler = () => {
        this.setState({sending: true})

        const data = {
            password: this.state.password,
            token: this.props.location.search.split("=")[1]
        }

        Axios.post(window.backendURL + "lost-password-reset", data)
            .then(() => this.setState({isPasswordReset: true}))
            .catch(error => this.setState({errorData: error.response.data}))
            .then(() => this.setState({sending: false}))
    }

    validation = () => {
        const
            {password, passwordAgain} = this.state,
            isPasswordMatch = password === passwordAgain

        this.setState({
            isPasswordMatch,
            showNoMatch: !isPasswordMatch && passwordAgain !== ""
        })
    }

    render () {
        const
            {
                errorData,
                isPasswordMatch,
                isPasswordReset,
                sending,
                showNoMatch,
            } = this.state,
            alert = errorData.code && !sending
                        ? <div className="border-danger card mb-4">
                            <div className="card-header bg-danger">
                                <code className="text-white">
                                    {errorData.code}
                                </code>
                            </div>
                            <div className="card-body">
                                <code>{errorData.message}</code>
                            </div>
                        </div>
                        : null,
            {t} = this.props,
            pageT = string => t("resetPassword." + string),
            resetHtml = <p className="text-center mb-0">
                <a href="/">{pageT("backToLogin")}</a>
            </p>,
            resetForm = <form>
                <div className="form-group">
                    <label
                        className="control-label mb-10"
                        htmlFor="password">
                        {pageT("passwordLabel")}
                    </label>
                    <input
                        autoComplete="on"
                        className="form-control"
                        id="password"
                        onChange={this.inputHandler}
                        value={this.state.password}
                        type="password" />
                </div>
                <div className="form-group">
                    <label
                        className="control-label mb-10"
                        htmlFor="passwordAgain">
                        {pageT("passwordAgainLabel")}
                    </label>
                    <input
                        autoComplete="on"
                        className={"form-control"
                                    + (showNoMatch ? " is-invalid" : "")}
                        id="passwordAgain"
                        onBlur={this.validation}
                        onChange={this.inputHandler}
                        value={this.state.passwordAgain}
                        type="password" />
                    {showNoMatch
                        ? <div className="invalid-feedback">
                            {pageT("confirmPasswordFail")}
                        </div>
                        : null}
                </div>
                <div className="text-center">
                    <button
                        className="btn btn-info btn-rounded"
                        disabled={!isPasswordMatch || sending}
                        onClick={this.submitHandler}
                        type="button">
                        {sending
                            ? <>
                                <div className="align-middle mr-2 spinner-border
                                                spinner-border-sm" />
                                {pageT("sending")}
                            </>
                            : pageT("submit")}
                    </button>
                </div>
            </form>

        return <div
            className="py-3 vh-100"
            style={{display: "grid", placeItems: "center"}}>
            <div className="auth-form">
                <div className="mb-4 text-center">
                    <img
                        alt="brand"
                        className="brand-img mb-3"
                        src="dist/img/fav/logo-full.png"
                        width="240px" />
                    <h4 className="text-center text-dark mb-10">
                        {t("system.title")}
                    </h4>
                </div>
                {alert}
                <div className="card-view mb-0 p-3">
                    <h4 className="text-center text-dark mb-10">
                        {pageT(isPasswordReset ? "passwordReset" : "title")}
                    </h4>
                    <hr />
                    {isPasswordReset ? resetHtml : resetForm}
                </div>
            </div>
        </div>
    }

}

export default ResetPassword;