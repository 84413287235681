import Axios from "axios"
import DataTable from "react-data-table-component"
import Moment from "react-moment"
import React, { useEffect, useState } from "react"

const heartBeatInterval = 1000 * 60 * 6 // One heartbeat record every 5 minutes

const Meter = props => {
    const
        meterId = props.match ? props.match.params.meterId : "",
        [status, setStatus] = useState(""),
        [loading, setLoading] = useState(false),
        [searchId, setSearchId] = useState(meterId),
        [tableData, setTableData] = useState([]),
        { langHandler, langOptions, nowLang, userData } = props,
        langMenu = langOptions.map((option, i) => {
            const { label, value } = option

            let listItem = null

            if (nowLang.value !== value) {
                listItem = <li key={"option-" + i}>
                    <button
                        className="px-3 py-1"
                        data-label={label}
                        data-value={value}
                        onClick={
                            langHandler.bind(this, option, { name: label })
                        }>
                        <i className="text-center zmdi zmdi-globe-alt"></i>
                        {nowLang.label}
                        <i className="mx-1 text-center zmdi
                                        zmdi-long-arrow-right"></i>
                        {label}
                    </button>
                </li>
            }

            return listItem
        }),
        statusIconClasses = "material-icons mr-2 text-"
            + (status === "connected" ? "success" : "danger"),
        statusIcon = status === "connected"
            ? "check_circle_outline"
            : "highlight_off",
        t = props.translation,
        tableColumns = [
            {
                cell: row => row.logDate
                    ? <Moment>{row.logDate}</Moment>
                    : "-",
                center: true,
                name: t("meter.recordTime"),
                selector: "logDate"
            },
            { center: true, name: t("meter.recordId"), selector: "recordId" },
            {
                cell: row => row.reading1
                    ? (row.reading1 / 1000).toFixed(2)
                    : "-",
                center: true,
                name: t("meter.reading"),
                selector: "reading1"
            },
            {
                cell: row => row.ReceivedAt
                    ? <Moment>{row.ReceivedAt}</Moment>
                    : "-",
                center: true,
                name: t("meter.receiveTime"),
                selector: "ReceivedAt"
            }
        ]

    let style

    if (!meterId) {
        style = {
            display: "grid",
            height: "calc(100vh - 70px - 4rem)",
            minHeight: "260px",
            placeItems: "center"
        }
    }

    useEffect(() => {
        const getData = () => {
            if (meterId) {
                setLoading(true)

                const
                    config = { headers: { Authorization: userData.authToken } },
                    detailApiurl = window.backendURL + "meter/" + meterId,
                    lpApiUrl = window.backendURL + "trio/loadProfile/" + meterId
                        + "?pageIndex=0&pageSize=30"
                        + "&sortField=log_date&sortDirection=desc"

                let detailDone = false, lpDone = false

                Axios.get(detailApiurl, config)
                    .then(response => {
                        if (response.data.meter) {
                            const
                                currentTime = new Date().getTime(),
                                { meter } = response.data,
                                lastTime = meter.LastResponseReceived
                                    ? new Date(meter.LastResponseReceived)
                                        .getTime()
                                    : null

                            let status = "disconnected"

                            if (lastTime
                                && currentTime - lastTime < heartBeatInterval) {
                                status = "connected"
                            }

                            setStatus(status)
                        }
                    })
                    .catch()
                    .then(() => {
                        detailDone = true

                        if (lpDone) { setLoading(false) }
                    })
                Axios.get(lpApiUrl, config)
                    .then(response => setTableData(response.data.loadProfiles))
                    .catch()
                    .then(() => {
                        lpDone = true

                        if (detailDone) { setLoading(false) }
                    })
            }
        }

        getData()
    }, [meterId, userData])

    const changeSearchId = e => setSearchId(e.target.value)

    const search = () => window.location = "/monitor/" + searchId

    return <>
        <nav className="justify-content-end navbar py-3">
            <div className="dropdown">
                <a
                    className="align-content-center d-flex"
                    data-toggle="dropdown"
                    href="/#">
                    {userData.name}
                    <span className="material-icons">arrow_drop_down</span>
                </a>
                <ul className="dropdown-menu dropdown-menu-right ws-nowrap">
                    {langMenu}
                    <li>
                        <a
                            className="px-3 py-1"
                            href="/#"
                            onClick={props.logoutHandler}>
                            <i className="text-center zmdi zmdi-power" />
                            <span>{t("system.logout")}</span>
                        </a>
                    </li>
                </ul>
            </div>
        </nav>
        <div className="container" style={style}>
            <div>
                <div className="mb-2 text-center">
                    <img
                        alt="logo"
                        src="/dist/img/fav/logo-full.png"
                        style={{ width: "200px" }} />
                </div>
                <div className="form-group flex-horizontal
                                justify-content-center">
                    <label className="control-label mr-2 ws-nowrap">
                        {t("meter.meterId")}
                    </label>
                    <input
                        className="form-control text-secondary"
                        name="searchId"
                        onChange={changeSearchId}
                        // placeholder={t("meter.search")}
                        placeholder={t("external.searchHint")}
                        style={{ minWidth: "250px" }}
                        type="text"
                        value={searchId} />
                    <button className="btn btn-default ml-2" onClick={search}>
                        <i className="material-icons">search</i>
                    </button>
                </div>
            </div>
            {meterId
                ? tableData.length === 0
                    ? loading
                        ? <div className="d-flex justify-content-center mt-4">
                            <div
                                className="spinner-border txt-red"
                                role="status"
                                style={{
                                    animationDuration: "1s",
                                    borderWidth: "4px",
                                    height: "3rem",
                                    width: "3rem"
                                }}>
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                        : <div className="card p-3 text-black-50 text-center">
                            {t("dataTable.noDataMsg")}
                        </div>
                    : <>
                        <div className="row mt-4">
                            <div className="col-md-6">
                                <div className="panel panel-primary card-view">
                                    <div className="c-state text-left">
                                        <h4 className="weight-lighter">{meterId}</h4>
                                        <hr className="row border-top-2" />
                                        <span>{t("meter.meterId")}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="panel panel-primary card-view">
                                    <div className="c-state text-left">
                                        <h4 className="weight-lighter">
                                            <i
                                                className={statusIconClasses}
                                                style={{ fontSize: "1em" }}>
                                                {statusIcon}
                                            </i>
                                            {status
                                                ? t("meter.status." + status)
                                                : null}
                                        </h4>
                                        <hr className="row border-top-2" />
                                        <span>{t("meter.meterStatus")}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="panel panel-primary card-view">
                                    <div className="c-state text-left">
                                        <h4 className="weight-lighter">
                                            <Moment>{tableData[0].ReceivedAt}</Moment>
                                        </h4>
                                        <hr className="row border-top-2" />
                                        <span>{t("meter.receiveTime")}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="panel panel-primary card-view">
                                    <div className="c-state text-left">
                                        <h4 className="weight-lighter">
                                            {tableData[0].FirmwareVersion || "-"}
                                        </h4>
                                        <hr className="row border-top-2" />
                                        <span>
                                            {t("external.currentFirmwareVersion")}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <DataTable
                            columns={tableColumns}
                            data={tableData}
                            noHeader={true}
                            pagination={false} />
                    </>
                : null}
        </div>
    </>
}

export default Meter