import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import axios from 'axios';

const env = process.env.NODE_ENV

axios.interceptors.request.use(config => {
    // config.headers.post['Authorization'] = 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE1NzU2Mjc1MzcsInVzZXJJZCI6MiwiZW1haWwiOiJtYXR0QHViaWlrLmNvbSIsInJvbGUiOjIsIm5hbWUiOiJNYXR0IFdsYXpsbyJ9.lbc6yJl7NtwULEYOJ2TYppNQqqgr-5SZEIY2uSHSihM';
    config.crossDomain = true;
    config.headers.post['Content-Type'] ='application/x-www-form-urlencoded';

    if (env === "development") {
        console.log(config)
    }
    // config.headers['Access-Control-Allow-Origin'] = "*";
    // config.headers['Content-Type'] ='application/x-www-form-urlencoded';
    
    return config;
}, error => {
    console.log(error);
    return Promise.reject(error);
});

axios.interceptors.request.use(request => {
    if (env === "development") {
        console.log(request)
    }
    return request;
}, error => {
    console.log(error);
    return Promise.reject(error);
});

axios.interceptors.response.use(response => {
    if (env === "development") {
        console.log(response)
    }
    return response;
}, error => {
    if(error.response.data.code === "TOKENLOGOUT"){
        window.location ="/Logout";
    }
    return Promise.reject(error);
});



ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

