const EmailRule = /^\w+((-\w+)|(\.\w+)|(\+\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/

const parseMeterList = data => data?.map?.(item => {
    const { ID, Lat, LastActive, Lng, ParentID } = item
    return ({
        ID,
        lastTime: Math.max(
            new Date(item.LastEventReceived),
            new Date(item.LastResponseReceived)
        ),
        Lat,
        LastActive,
        Lng,
        ParentID
    })
}) || []

export { EmailRule, parseMeterList }