import React, { Component } from 'react';
import Axios from 'axios';
import Select from 'react-select';

class LoginPage extends Component{
    constructor (props) {
        super(props)

        this.state = {
            defaultLang: this.props.defaultLang,
            loginMessage: "",
            password: "",
            username: ""
        }
    }

    inputHandler = (event) => {
        const targetName = event.target.name;
        const targetVal = event.target.value;
        const update = {};
        update[targetName] = targetVal;
        this.setState(update);

    }

    loginHandler = (event) => {
        const data = {
            email: this.state.username,
            password: this.state.password
        }
        const {translation} = this.props

        Axios.post(window.backendURL + 'login',data)
            .then(response => {
                const
                    authToken = response.headers.authorization,
                    headers = {headers: {Authorization: authToken}}

                    let userData = {authToken, email: this.state.username}

                Axios.get(window.backendURL + "userInfo", headers)
                    .then(response => {
                        const {id, name, role} = response.data.data

                        userData.id = id
                        userData.name = name
                        userData.role = role
                    })
                    .then(() => this.props.userDataHandler(userData))
            })
            .catch(error => {
                if(error.response.data.code === "EPASS"){
                    alert(translation("login.changePWReminder"));
                    window.location ="/ForgotPassword";
                }

                if(error.response.data.code === "ECRED"){
                    alert(translation("login.loginFail"));
                }

                if(error.response.data.code === "EBLOCK"){
                    alert(error.response.data.message);
                }

                if(error.response.status === 401){
                    this.setState({loginMessage: "Login Failed"})
                }

                console.log(error);
            });
    }



    render(){
        const t = this.props.translation, pageT = string => t("login." + string)

        return (
            <div className="wrapper pa-0">
                <div className="page-wrapper pa-0 ma-0 auth-page">
                    <div className="container-fluid">
                    <div className="table-struct full-width full-height">
                        <div className="table-cell vertical-align-middle auth-form-wrap">
                        <div className="auth-form ml-auto mr-auto no-float">
                            <div className="row">
                            <div className="col-sm-12 col-xs-12">
                                <div className="mb-30 text-center"><img className="brand-img" src="/dist/img/fav/rakuten-logo.png" alt="brand" width="240px" />
                                <h4 className="text-center txt-dark mb-10">{this.props.translation('system.title')}</h4>
                                </div>
                                <div className="form-wrap panel card-view">
                                <h3 className="text-center txt-dark mb-10">{this.props.translation('login.title')}</h3>
                                    <div className="form-group">
                                    <label className="control-label mb-10" htmlFor="lang">{this.props.translation('login.language')}</label>
                                    <Select
                                        id="lang"
                                        name="lang"
                                        defaultValue={this.state.defaultLang}
                                        onChange={this.props.langHandler}
                                        options={this.props.langOptions} />
                                    </div>
                                    <div className="form-group">
                                    <label className="control-label mb-10" htmlFor="username">{this.props.translation('login.account')}</label>
                                    <input className="form-control" id="username" name="username"  type="text" required="" placeholder="" onChange={this.inputHandler} value={this.state.username} />
                                    </div>
                                <div className="form-group">
                                    <div className="d-flex
                                                    justify-content-between
                                                    mb-10">
                                        <label htmlFor="password">
                                            {pageT("password")}
                                        </label>
                                        <a
                                            className="font-12"
                                            href="/ForgotPassword">
                                            {pageT("forgotPassword")}
                                        </a>
                                    </div>
                                    <input
                                        className="form-control"
                                        id="password"
                                        name="password"
                                        onChange={this.inputHandler}
                                        type="password"
                                        value={this.state.password} />
                                </div>

                                    <div className="form-group text-center">
                                    <button className="btn btn-info btn-rounded" type="submit" onClick={this.loginHandler}>{this.props.translation('login.loginBtn')}</button>
                                    </div>

                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        );

    }

}

export default LoginPage;