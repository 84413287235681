import React, { Component } from 'react';
import DataTable from 'react-data-table-component';
import Axios from 'axios';
import Moment from 'react-moment';

class LoadProfile extends Component{
    constructor(props){
        super(props)

        this.state = {
            headers: [],
            loading: false,
            meterId: props.location.search.split("=")[1],
            perPage: 10,
            tableList: [],
            totalRows: 0,
        }
    }

    componentDidMount () {this.getData(0, this.state.perPage)}

    getData = (pageIndex, pageSize) => {
        this.setState({loading: true})

        const
            apiUrl = window.backendURL + "trio/loadProfile/"
                        + this.state.meterId + "?pageIndex=" + pageIndex
                        + "&pageSize=" + pageSize
                        + "&sortField=log_date&sortDirection=desc",
            config = {headers: {Authorization: this.props.userData.authToken}}

        Axios.get(apiUrl, config)
            .then(response => {
                if (response.data) {
                    const {loadProfiles, total} = response.data

                    this.setState({tableList: loadProfiles, totalRows: total})
                }
            })
            .catch(error => console.error(error))
            .then(() => this.setState({loading: false}))
    }

    handlePageChange = page => this.getData((page - 1), this.state.perPage)

    handlePerRowsChange = (perPage, page) => {
        this.getData((page - 1), perPage)
        this.setState({perPage})
    }

    inputHandler = e => this.setState({[e.target.name]: e.target.value})

    searchHandler = () => this.getData(0, this.state.perPage)

    render(){
        const
            {loading, tableList} = this.state,
            t = this.props.translation,
            tableColumns = [
                {
                    cell: row => row.logDate
                                    ? <Moment>{row.logDate}</Moment>
                                    : "-",
                    center: true,
                    name: t("meter.recordTime"),
                    selector: "logDate"
                },
                {center: true, name: t("meter.meterId"), selector: "meterId"},
                {center: true, name: t("meter.fanId"), selector: "fanId"},
                {center: true, name: t("meter.recordId"), selector: "recordId"},
                {
                    cell: row => row.reading1
                            ? (row.reading1 / 1000).toFixed(2)
                            : "-",
                    center: true,
                    name: t("meter.reading"),
                    selector: "reading1"
                },
                {
                    cell: row => row.ReceivedAt
                                    ? <Moment>{row.ReceivedAt}</Moment>
                                    : "-",
                    center: true,
                    name: t("meter.receiveTime"),
                    selector: "ReceivedAt"
                }
            ]

        return <div className="container-fluid">
            <ol className="breadcrumb mb-4">
                <li>
                    <a href="/RakutenManagement/Meter">
                        {t("system.meterManagement")}
                    </a>
                </li>
                <li><span>{t("meter.loadProfile")}</span></li>
                <li className="active"><span>{this.state.meterId}</span></li>
            </ol>
            <div className="clearfix" />
            <div className="title-hr"><h5>{this.state.meterId}</h5></div>
            <DataTable
                columns={tableColumns}
                data={tableList}
                noHeader={true}
                onChangePage={this.handlePageChange}
                onChangeRowsPerPage={this.handlePerRowsChange}
                pagination={true}
                paginationServer={true}
                paginationTotalRows={this.state.totalRows}
                progressPending={loading} />
        </div>
    }
}

export default LoadProfile;