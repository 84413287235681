import React from "react"

const ErrorMessage = props => {
    let errorMsg = {}

    if (props.data) {
        const error = props.data, errorJson = error.toJSON()

        if (error.response && error.response.data.code) {
            errorMsg = error.response.data
        }
        else {
            errorMsg = {code: errorJson.name, msg: errorJson.message}
        }
    }

    const {code, data, message, msg} = errorMsg

    return code
            ? <div className={props.className}>
                <strong>{code}</strong>: {data || (msg || message)}
            </div>
            : null
}

export default ErrorMessage