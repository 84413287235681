import React, { Component } from 'react';
import Axios from 'axios';

class ForgotPassword extends Component{
    state = {email: "", errorData: {}, isEmailSent: false, sending: false}

    inputHandler = e => this.setState({email: e.target.value})

    submitHandler = () => {
        this.setState({sending: true})

        const {email} = this.state

        Axios.post(window.backendURL + "lost-password", {email})
            .then(() => this.setState({isEmailSent: true}))
            .catch(error => this.setState({errorData: error.response.data}))
            .then(() => this.setState({sending: false}))
    }

    render(){
        const
            {errorData, sending} = this.state,
            alert = errorData.code && !sending
                        ? <div className="border-danger card mb-4">
                            <div className="card-header bg-danger">
                                <code className="text-white">
                                    {errorData.code}
                                </code>
                            </div>
                            <div className="card-body">
                                <code>{errorData.message}</code>
                            </div>
                        </div>
                        : null,
            {t} = this.props,
            emailSentHtml = <>
                <hr className="mb-3" />
                <p className="text-center mb-0">
                    <a className="btn btn-primary" href="/">
                        {t("forgotPassword.backToLogin")}
                    </a>
                </p>
            </>,
            resetForm = <>
                <p>{t("forgotPassword.description")}</p>
                <hr />
                <div className="form-group">
                    <label
                        className="control-label mb-10"
                        htmlFor="email">{t("forgotPassword.email")}</label>
                    <input
                        className="form-control"
                        id="email"
                        onChange={this.inputHandler}
                        value={this.state.email}
                        type="email" />
                </div>
                <div className="text-center">
                    <button
                        className="btn btn-info btn-rounded"
                        disabled={!this.state.email || sending}
                        onClick={this.submitHandler}
                        type="button">
                        {sending
                            ? <>
                                <div className="align-middle mr-2 spinner-border
                                                spinner-border-sm" />
                                {t("forgotPassword.sending")}
                            </>
                            : t("forgotPassword.resetBtn")}
                    </button>
                </div>
            </>

        return (
            <div
                className="vh-100 py-3"
                style={{display: "grid", placeItems: "center"}}>
                <div className="auth-form">
                    <div className="mb-4 text-center">
                        <img
                            alt="brand"
                            className="brand-img mb-3"
                            src="dist/img/fav/logo-full.png"
                            width="240px" />
                        <h4 className="text-center text-dark mb-10">
                            {t("system.title")}
                        </h4>
                    </div>
                    {alert}
                    <div className="card-view mb-0 p-3">
                        <h3 className="text-center text-dark mb-10">
                            {t("forgotPassword." + (this.state.isEmailSent
                                                    ? "emailSent"
                                                    : "title"))}
                        </h3>
                        {this.state.isEmailSent
                            ? emailSentHtml
                            : resetForm}
                    </div>
                </div>
            </div>
        )
    }
}

export default ForgotPassword;