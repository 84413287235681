import Axios from "axios"
import {Button, Modal} from "react-bootstrap"
import DataTable from "react-data-table-component"
import React, {useCallback, useEffect, useState} from "react"

import {EmailRule} from "../../../Utils"
import ErrorMessage from "../../../ErrorMessage"

export default function Alert (props) {
    const
        baseUrl = window.backendURL + "rakuten/config/",
        alertApi = baseUrl + "toggle-lost-meters-checking",
        mailApi = baseUrl + "alert-mail-list"

    const
        [alertChanged, setAlertChanged] = useState(false),
        [alertDefault, setAlertDefault] = useState(false),
        [alertGetError, setAlertGetError] = useState(null),
        [alertGetting, setAlertGetting] = useState(true),
        [alertPutError, setAlertPutError] = useState(null),
        [alertSending, setAlertSending] = useState(false),
        [alertStatus, setAlertStatus] = useState(false),
        [emailAdd, setEmailAdd] = useState(""),
        [emailAddError, setEmailAddError] = useState(null),
        [emailAdding, setEmailAdding] = useState(false),
        [emailDelete, setEmailDelete] = useState(""),
        [emailDeleteError, setEmailDeleteError] = useState(null),
        [emailDeleting, setEmailDeleting] = useState(false),
        [mailList, setMailList] = useState([]),
        [mailListError, setMailListError] = useState(null),
        [mailListLoading, setMailListLoading] = useState(true),
        [showDeleteModal, setShowDeleteModal] = useState(false)

    const apiHeaders = {headers: {Authorization: props.userData.authToken}}

    const
        addMail = () => {
            setEmailAdding(true)
            Axios.post(mailApi, {email: emailAdd}, apiHeaders)
                .then(() => {
                    getMailList()
                    setEmailAdd("")
                    setEmailAddError(null)
                })
                .catch(error => setEmailAddError(error))
                .then(() => setEmailAdding(false))
        },
        changeAlertStatus = e => {
            const status = e.target.value === "true"

            setAlertChanged(status !== alertDefault)
            setAlertStatus(status)
        },
        deleteMail = () => {
            const config = {
                ...apiHeaders,
                data: {email: emailDelete},
                method: "DELETE",
                url: mailApi
            }

            setEmailDeleting(true)
            Axios(config)
                .then(() => {
                    getMailList()
                    setEmailDelete("")
                    setEmailDeleteError(null)
                    setShowDeleteModal(false)
                })
                .catch(error => setEmailDeleteError(error))
                .then(() => setEmailDeleting(false))
        },
        getMailList = useCallback(() => {
            setMailListLoading(true)
            Axios.get(mailApi, apiHeaders)
                .then(response => {
                    setMailList(response.data.data)
                    setMailListError(null)
                })
                .catch(error => setMailListError(error))
                .then(() => setMailListLoading(false))
        }, [mailApi, apiHeaders]),
        submitAlert = () => {
            const data = {enabled: alertStatus}

            setAlertSending(true)
            Axios.put(alertApi, data, apiHeaders)
                .then(() => {
                    setAlertChanged(false)
                    setAlertDefault(alertStatus)
                    setAlertPutError(null)
                })
                .catch(error => setAlertPutError(error))
                .then(() => setAlertSending(false))
        }

    useEffect(() => {
        Axios.get(alertApi, apiHeaders)
            .then(response => {
                const status = response.data.enabled

                setAlertDefault(status)
                setAlertGetError(null)
                setAlertStatus(status)
            })
            .catch(error => setAlertGetError(error))
            .then(() => setAlertGetting(false))

        getMailList()
        // eslint-disable-next-line
    }, [])

    const
        alertBoxStyle = "alert alert-warning mb-0 text-center",
        t = props.translation,
        pageT = string => t("alert." + string),
        tableColumns = [
            {
                name: pageT("email"),
                selector: "email",
                sortable: true
            },
            {
                cell: row => <i
                    className="cursor-pointer material-icons txt-red"
                    data-toggle="modal"
                    data-target="#deleteModal"
                    onClick={() => {
                        setEmailDelete(row.email)
                        setShowDeleteModal(true)
                    }}>
                    delete
                </i>,
                center: true,
                name: pageT("delete"),
                width: "10em"
            }
        ]

    return <div className="container-fluid">
        <ol className="breadcrumb">
            <li>{t("system.settings")}</li>
            <li className="active">{t("system.alertNotification")}</li>
        </ol>
        <div className="clearfix" />
        <ul className="nav nav-justified nav-tabs mb-4">
            <li className="nav-item active">
                <a className="nav-link" href="/RakutenManagement/Alert">
                    {t("system.alertNotification")}
                </a>
            </li>
            <li className="nav-item">
                <a className="nav-link" href="/RakutenManagement/Installation">
                    {t("system.installation")}
                </a>
            </li>
        </ul>
        <h5 className="mb-2">{pageT("alertSetting")}</h5>
        <ErrorMessage className={alertBoxStyle} data={alertGetError} />
    {alertGetError
        ? null
        : <div className="form-inline">
            <label htmlFor="alert">{pageT("alert")}</label>
            <select
                className="custom-select mx-2"
                disabled={alertGetting || alertSending}
                id="alert"
                onChange={changeAlertStatus}
                value={alertChanged ? alertStatus : alertDefault}>
                <option value={true}>{pageT("alertOn")}</option>
                <option value={false}>{pageT("alertOff")}</option>
            </select>
        {alertGetting ? pageT("loading") : null}
        {alertChanged && !alertSending
            ? <>
            <button
                className="btn btn-primary"
                onClick={submitAlert}>
                {pageT("apply")}
            </button>
            <button
                className="btn btn-secondary ml-2"
                onClick={() => setAlertChanged(false)}>
                {pageT("cancel")}
            </button>
            </>
            : null}
        {alertSending ? pageT("sending") : ""}
        </div>}
        <ErrorMessage className={alertBoxStyle} data={alertPutError} />
        <hr className="my-4" />
        <h5 className="mb-2">{pageT("alertMailList")}</h5>
        <ErrorMessage className={alertBoxStyle} data={mailListError} />
        <div className="form-inline my-3">
            <label htmlFor="emailAdd">
                {pageT("email")}
            </label>
            <input
                className="form-control mx-2"
                disabled={emailAdding}
                id="emailAdd"
                onChange={e => {
                    setEmailAdd(e.target.value)
                    setEmailAddError(null)
                }}
                type="text"
                value={emailAdd} />
            <button
                className="btn btn-primary"
                disabled={emailAdding || !EmailRule.test(emailAdd)}
                onClick={addMail}>
                {pageT(emailAdding ? "sending" : "add")}
            </button>
        {emailAdd && !EmailRule.test(emailAdd)
            ? <span className="ml-2 text-danger">
                {pageT("emailInvalid")}
            </span>
            : null}
            <ErrorMessage className="ml-2 text-danger" data={emailAddError} />
        </div>
        <DataTable
            className="d-inline-flex"
            columns={tableColumns}
            data={mailList}
            noHeader={true}
            progressPending={mailListLoading} />
        <Modal
            onHide={() => {
                setEmailDelete("")
                setShowDeleteModal(false)
            }}
            show={showDeleteModal}>
            <Modal.Header closeButton>
                <Modal.Title>{pageT("delete")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className="mb-3">{emailDelete}</p>
                <ErrorMessage
                    className={alertBoxStyle}
                    data={emailDeleteError} />
            </Modal.Body>
            <Modal.Footer>
                <Button
                    onClick={() => {
                        setEmailDelete("")
                        setShowDeleteModal(false)
                    }}
                    variant="secondary">
                    {pageT("cancel")}
                </Button>
                <Button
                    disabled={emailDeleting}
                    onClick={deleteMail}
                    variant="primary">
                    {pageT(emailDeleting ? "sending" : "delete")}
                </Button>
            </Modal.Footer>
        </Modal>
    </div>
}