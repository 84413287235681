import React from 'react';
import {useLocation} from "react-router-dom"
import './Items.css'

function Items(props){
    const
        {pathname} = useLocation(),
        {subPages, target} = props,
        isActive = target === pathname
                    || (subPages && subPages.filter(v => pathname.includes(v))
                                    .length > 0)

    let itemHTML;

    if(props.level === "1"){
        itemHTML = (
            <li className={props.className}>
                <a
                    className={"d-flex" + (isActive ? " active" : "")}
                    href={props.target}>
                    <i className="material-icons">{props.materialIcons}</i>
                    <span className="right-nav-text">{props.title}</span>
                </a>
            </li>
        );
    }
    else{
        if (props.target === '#') {
            itemHTML = (
                <li className="upcoming">
                    {props.title}
                    <i
                        className="material-icons ml-1"
                        data-toggle="tooltip"
                        title="Not supported">information</i>
                </li>
            )
        }
        else {
            itemHTML = (
                <li>
                    <a
                        className={isActive ? "active" : ""}
                        href={props.target}>{props.title}</a>
                </li>
            );
        }
    }

    return (itemHTML);
}


export default Items;