import React from "react"

const Spinner = props => {
    const
        {className, dimension, duration, fontSize} = props,
        style = {}

    if (fontSize) {
        style.fontSize = fontSize
    }

    if (dimension) {
        style.height = dimension
        style.width = dimension
    }

    if (duration) {
        style["animation-duration"] = duration
    }

    return <div
        className={"spinner-border" + (className ? " " + className : "")}
        role="status"
        style={style}>
        <span className="sr-only">Loading...</span>
    </div>
}

export default Spinner