import { createBrowserHistory } from "history"
import DataTable from "react-data-table-component"
import L from "leaflet"
import ls from "local-storage"
import Moment from "react-moment"
import moment from "moment"
import React, {Component} from "react"
import {setDefaultLanguage, setLanguage, setTranslations, t, withTranslation}
    from "react-multi-lang"

import ContentPage from "../ContentPage/ContentPage"
import DTPagination from "../../components/DTPagination/DTPagination"
import External from "../../components/External/External"
import LandingPage from "../LandingPage/LandingPage"

import cht from "../../translation/cht.json"
import en from "../../translation/en.json"
import jp from "../../translation/jp.json"

DataTable.defaultProps.className = "reactDataTable"
DataTable.defaultProps.paginationComponent = DTPagination

DTPagination.contextType = React.createContext({translation: t})

delete L.Icon.Default.prototype._getIconUrl

L.Icon.Default.mergeOptions({
    iconRetinaUrl: "/images/marker-icon-2x.png",
    iconUrl: "/images/marker-icon.png",
    shadowUrl: "/images/marker-shadow.png"
})

Moment.globalTimezone = "Asia/Tokyo"
moment.tz.setDefault("Asia/Tokyo")

setTranslations({jp, en, cht})
setDefaultLanguage("jp")

const langOptions = [
    {
        value: "en",
        label: "English"
    },
    {
        value: "jp",
        label: "日本語"
    },
    {
        value: "cht",
        label: "繁體中文"
    }
]

class MainPage extends Component{
    constructor(props){
        super(props);
    
        var nowLang = ls.get('lang');
        
        var langObj = {};
        if(nowLang !== null){
            if(nowLang === "jp"){
                langObj = {
                    "label": "日本語",
                    "value": "jp"
                }
            }
            else if(nowLang === "cht"){
                langObj = {
                    "label": "繁體中文",
                    "value": "cht"
                }
            }
            else{
                langObj = {
                    "label": "English",
                    "value": "en"
                }
            }
            
        }
        else{
            langObj = {
                "label": "日本語",
                "value": "jp"
            }
        }

        const userDataStorage = ls.get("userData")

        this.state = {nowLang: langObj, userData: userDataStorage || {}}

        const
            apiEndpoint = process.env.REACT_APP_BACKEND_API_ENDPOINT,
            apiPath = process.env.REACT_APP_API_PATH

        window.backendURL = (apiEndpoint || window.location.origin) + apiPath
    }

    componentDidMount(){
        var nowLang = ls.get('lang');
        var langObj = {};
        
        if(nowLang !== null){
            if(nowLang === "jp"){
                langObj = {
                    "label": "日本語",
                    "value": "jp"
                }
            }
            else if(nowLang === "cht"){
                langObj = {
                    "label": "繁體中文",
                    "value": "cht"
                }
            }
            else{
                langObj = {
                    "label": "English",
                    "value": "en"
                }
            }
            
        }
        else{
            langObj = {
                "label": "日本語",
                "value": "jp"
            }
        }
        this.setState({
            nowLang: langObj
        });
        setLanguage(this.state.nowLang.value)
        
        let hours = 1;
        let now = new Date().getTime();
        let setupTime = ls.get("setupTime");
        if (setupTime === null || (now-setupTime) > hours*60*60*1000) {
            ls.clear();
            this.setState({userData: {}})
        }

        const {t} = this.props

        DataTable.defaultProps.noDataComponent
            = <div
                className="align-items-center d-flex text-primary"
                style={{fontSize: "1rem", lineHeight: 2}}>
                {t("dataTable.noDataMsg")}
            </div>
        DataTable.defaultProps.progressComponent
            = <div
                className="align-items-center d-flex text-primary"
                style={{height: "calc(2rem + 32px)"}}>
                <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
    }

     
    langHandler = (optionSelected, actionMeta) => {
        const targetName = actionMeta.name;
        const targetVal = optionSelected.value;
    
        setLanguage(targetVal);
        this.setState({
            label: targetName,
            nowLang: optionSelected,
            value: targetVal
        });
        ls.set("lang", targetVal);
    }

    userDataHandler = userData => {
        let now = new Date().getTime()

        ls.set("setupTime", now)
        ls.set("userData", userData)
        this.setState({userData})
    }

    routeHandler = (route) => {
        const history = createBrowserHistory();
        history.push(route);
    }

    logoutHandler = () => {
        ls.clear();
        this.setState({userData: {}})

        const history = createBrowserHistory();
        history.push("/");
    }

    render(){
        const
            commonProps = {
                langHandler: this.langHandler,
                langOptions,
                userData: this.state.userData
            },
            {id, role} = this.state.userData

        if (!id) {
            return <LandingPage
                    {...commonProps}
                    defaultLang={this.state.nowLang}
                    translation={t}
                    userDataHandler={this.userDataHandler} />
        }
        else{
            if (role === "monitor") {
                return <External
                        {...commonProps}
                        logoutHandler={this.logoutHandler}
                        nowLang={this.state.nowLang}
                        translation={t} />
            }
            else {
                return <ContentPage
                        {...commonProps}
                        logoutHandler={this.logoutHandler}
                        nowLang={this.state.nowLang}
                        role={role}
                        translation={t} />
            }
        }
    }
}

export default withTranslation(MainPage);