import Axios from "axios"
import React, {Component} from "react"

import ErrorMessage from "../../ErrorMessage"

class ExportMeter extends Component {
    state = {errorData: null, downloading: false}

    downloadMeter = () => {
        const config = {
            data: {"ids": []},
            headers: {Authorization: this.props.userData.authToken},
            method: "POST",
            responseType: "blob",
            url: window.backendURL + "rakuten/meters/export-detail"
        }

        this.setState({downloading: false})
        Axios(config)
            .then(response => {
                const {data} = response

                if (data.size > 0) {
                    const link = document.createElement("a")

                    link.href = window.URL.createObjectURL(new Blob([data]))
                    link.setAttribute("download", "meters-detail.zip")
                    link.click()
                }
            })
            .catch(error => this.setState({meterDownloadError: error}))
            .then(() => this.setState({downloading: false}))
    }

    render () {
        const
            {downloading} = this.state,
            spinner =
                <div className="spinner-border spinner-border-sm" role="status">
                    <span className="sr-only">Loading...</span>
                </div>,
            t = this.props.translation,
            pageT = string => t("export." + string)

        return <div className="container-fluid">
            <ol className="breadcrumb mb-4">
                <li>{t("system.meterManageMenu")}</li>
                <li className="active">{t("system.exportMeter")}</li>
            </ol>
            <div className="clearfix" />
            <div className="row">
                <div className="col-lg-6">
                    <div className="card-view panel text-center">
                        <div className="panel-heading">
                            <i className="material-icons md-36 txt-grey">
                                cloud_download
                            </i>
                            <h6 className="panel-title">
                                {pageT("impactMeter")}
                            </h6>
                        </div>
                        <div className="mt-3 panel-footer pt-3">
                            <button
                                className="btn btn-primary"
                                disabled={downloading}
                                onClick={this.downloadMeter}>
                            {downloading
                                ? spinner
                                : pageT("downloadDetail")}
                            </button>
                            <ErrorMessage
                                className="mt-3 text-center text-danger"
                                data={this.state.errorData} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}

export default ExportMeter