import Axios from "axios"
import React, { Component } from 'react';
import moment from "moment"
import {Line} from 'react-chartjs-2';

class DeviceStatus extends Component{
    constructor(){
        super();
        this.state = {
            dynamicLabel : ["00:00","01:00","02:00","03:00","04:00","05:00","06:00"],
            dynamicData: [981, 1422, 966, 1881, 883, 1389, 1849],
            data: [],
            labels: []
        }
        this.chartRef = React.createRef();
    }

    componentDidMount() {
        const
            apiUrl = window.backendURL + "rakuten/online?q=hourly&limit=6",
            headers = {headers: {Authorization: this.props.userData.authToken}}

        Axios.get(apiUrl, headers)
            .then(response => {
                let data = [], index = [], labels = []

                if (response.data.data.length > 0) {
                    response.data.data.forEach(item => {
                        const time = moment(item.ts)
                        index.push({
                            count: item.count,
                            timestamp: time.unix(),
                            timeText: time.format("YYYY/MM/DD HH:mm")
                        })

                        index.sort((a, b) => a.timestamp - b.timestamp)
                    })

                    index.forEach(item => {
                        data.push(item.count)
                        labels.push(item.timeText)
                    })
                }

                this.setState({data, labels})
            })
    }

    render(){
        const data = {
            labels: this.state.labels,
            datasets: [{
                cubicInterpolationMode: "monotone",
                label: this.props.translation("meter.connected"),
                fill: false,
                backgroundColor: 'rgba(255, 0, 140, 0.4)',
                borderColor: 'rgba(255, 0, 140, .7)',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                pointStyle: "circle",
                pointBorderColor: 'rgba(255, 0, 140, 1)',
                pointBackgroundColor: 'rgba(255, 0, 140, 1)',
                pointHoverRadius: 5,
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderColor: 'rgba(255, 0, 140, 1)',
                pointHoverBorderWidth: 2,
                pointRadius: 3,
                pointHitRadius: 10,
                data: this.state.data,
                stepSize: 1
            }]
        }

        return (
            <div className="container-fluid">
       
            <div className="row heading-bg">
                <div className="col-12">
                <ol className="breadcrumb">
                <li><a href="/#">{this.props.translation("system.realtime")}</a></li>
                <li className="active"><span>{this.props.translation("system.meterConnection")}</span></li>
                </ol>
                </div>
            </div>
            <div className="row">
                <div className="form-group flex-horizontal ma-15">
                
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                <div className="panel card-view">
                    <div className="panel-wrapper">
                    <div className="panel-body">
                    <Line
                        data={data}
                        options={{
                            scales: {
                                yAxes: [
                                    {
                                        ticks: {
                                            beginAtZero: true,
                                            stepSize: 1
                                        },
                                    },
                                ],
                            }
                        }} />
                    </div>
                    </div>
                </div>
                </div>
            </div>
            
            

            </div>
        );
    }
}

export default DeviceStatus;