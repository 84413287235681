import Axios from "axios"
import DataTable from "react-data-table-component"
import {Helmet} from "react-helmet"
import Moment from "react-moment"
import "moment-timezone"
import React, {Component} from "react"

import LeafletMap from "../../../LeafletMap"
import MeterConnectionStatus from "../../../MeterConnectionStatus"

import { parseMeterList } from "../../../Utils"

class Dashboard extends Component{
    constructor (props) {
        super(props)

        this.state = {
            actualMeters: 0,
            loading: false,
            mapHeight: "300px",
            tableList: [],
            totalMeters: 0,
            zoom: 13
        }
        this.config = {headers: {Authorization: props.userData.authToken}}
     }

    getData = () => {
        this.setState({loading: true})

        Axios.post(window.backendURL + "meters", {}, this.config)
            .then(response => {
                if (!response.data?.meters) return
                const tableList = parseMeterList(response.data.meters)
                this.setState({ tableList })
            })
            .catch(error => console.error(error.response || error))
            .then(() => this.setState({loading: false}))
    }

    componentDidMount(){
        this.getData()

        const
            baseUrl = window.backendURL + "rakuten/",
            activeUrl = baseUrl + "online?q=daily&limit=1",
            countUrl = baseUrl + "config/installed-count"

        // Get Active Meters
        Axios.get(activeUrl, this.config)
            .then(response => {
                if (response.data.data) {
                    this.setState({actualMeters: response.data.data[0].count})
                }
            })
        // Get Total Count
        Axios.get(countUrl, this.config)
            .then(response =>
                this.setState({totalMeters: response.data.count || 0}))
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this))
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions.bind(this))

        if (window.doughnutChart) {
            window.doughnutChart.destroy()
            window.doughnutChart = null
        }
    }

    updateDimensions() {
        var height = window.innerWidth >= 1700 ? window.innerHeight : 300
        if(height > 450){
            height = 450;
        }
        this.setState({ mapHeight: height + "px" })
    }

    render(){
        const
            {actualMeters, loading, tableList, totalMeters} = this.state,
            coordinates = tableList.length > 0
                            ? tableList.filter(meter => meter.Lat && meter.Lng)
                                .map(meter => ({
                                    id: meter.ID, lat: meter.Lat, lng: meter.Lng
                                }))
                            : [],
            notConnected
                = totalMeters < actualMeters ? 0 : totalMeters - actualMeters,
            t = this.props.translation,
            tableColumns = [
                {name: t("meter.meterId"), selector: "ID", sortable: true},
                {name: t("meter.fanId"), selector: "ParentID", sortable: true},
                {
                    cell: row =>
                        <MeterConnectionStatus lastTime={row.lastTime} />,
                    center: true,
                    name: t("meter.meterStatus"),
                    selector: "lastTime",
                    sortable: true
                },
                {
                    cell: row => row.LastActive
                                    ? <Moment format="YYYY/MM/DD HH:mm:ss">
                                        {row.LastActive}
                                    </Moment>
                                    : "-",
                    center: true,
                    name: t("meter.receiveTime"),
                    selector: "LastActive",
                    sortable: true
                },
                {
                    cell: row =>
                        <a href={"/RakutenManagement/Meter/Detail/" + row.ID}>
                            <i className="material-icons txt-red">info</i>
                        </a>,
                    center: true,
                    name: t("meter.detail")
                }
            ]

        return (
            <div className="container-fluid">
            <div className="align-items-stretch mt-20 row">
              <div className="col-md-6 mb-4">
                <div
                    className="card-view mb-0 panel-table"
                    style={{minHeight: this.state.mapHeight}}>
                    <div className="h-100 panel-body">
                    {this.state.loading
                        ? <div
                            className="h-100 w-100"
                            style={{display: "grid", placeItems: "center"}}>
                            {t("dashboard.loading")}
                        </div>
                        : <LeafletMap
                            className="h-100 w-100"
                            coordinates={coordinates} />}
                    </div>
                </div>
              </div>
              <div className="col-md-6 mb-4">
                <div className="card-view justify-content-center mb-0
                                panel-table">
                  <div className="panel-wrapper">
                    <div className="panel-body">
                      <canvas id="chart_2" height="160"></canvas>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="table-responsive">
                  <div className="title-hr">
                    <h5 className="text-center">{this.props.translation("system.meterManagement")}</h5>
                  </div>
                  <DataTable
                    columns={tableColumns}
                    data={tableList}
                    defaultSortAsc={false}
                    defaultSortField="LastActive"
                    noHeader={true}
                    pagination={true}
                    progressPending={loading} />
                </div>
              </div>
            </div>

            <Helmet><script>{`
            $(document).ready(function() {
                "use strict";

                if ($("#chart_2")) {
                    if (!window.doughnutChart) {
                        var ctx7 = document.getElementById("chart_2")
                                    .getContext("2d");
                        var data7 = {
                            labels: [
                                "${t("meter.connectedMeters")}",
                                "${t("meter.notConnectedMeters")}"
                            ],
                            datasets: [{
                                data: [${actualMeters}, ${notConnected}],
                                backgroundColor: [
                                    "rgba(255, 0, 140,.8)",
                                    "rgba(245, 184, 72, .8)",
                                ],
                                hoverBackgroundColor: [
                                    "rgba(255, 0, 140,1)",
                                    "rgba(245, 184, 72, 1)",
                                ]
                            }]
                        };

                        var doughnutChart = new Chart(ctx7, {
                            type: 'doughnut',
                            data: data7,
                            options: {
                                height: "300px",
                                animation: {
                                    duration:	3000
                                },
                                responsive: true,
                                legend: {
                                    labels: {
                                        fontFamily: "Roboto",
                                        fontColor:"#878787"
                                    }
                                },
                                tooltip: {
                                    backgroundColor:'rgba(33,33,33,1)',
                                    cornerRadius:0,
                                    footerFontFamily:"'Roboto'"
                                },
                                elements: {
                                    arc: {
                                        borderWidth: 0
                                    }
                                },
                                maintainAspectRatio: true
                            }
                        })

                        window.doughnutChart = doughnutChart
                    }
                    else {
                        const
                            chartData
                                = window.doughnutChart.data.datasets[0].data,
                            chartDataLength = chartData.length,
                            dataToBeApplied
                                = [${actualMeters}, ${notConnected}],
                            isDifferent = dataToBeApplied.filter((v, i) =>
                                            v !== chartData[i]).length > 0

                        if (isDifferent) {
                            for (let i = 0; i < chartDataLength; ++i) {
                                chartData.pop()
                            }
                            for (let i = 0; i < dataToBeApplied.length; ++i) {
                                chartData.push(dataToBeApplied[i])
                            }

                            window.doughnutChart.update()
                        }
                    }
                }
            });
            `}</script></Helmet>
          </div>
        );
    }
}

export default Dashboard;