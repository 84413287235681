import DataTable from "react-data-table-component"
import React, { useEffect, useState } from "react"

import Axios from "axios"
import moment from "moment"
import { getLanguage, useTranslation } from "react-multi-lang"

import ErrorMessage from "../../ErrorMessage"
import Spinner from "../../Spinner"

const getLangTag = lang => {
    switch (lang) {
        case "cht":
            return "zh-TW"
        case "en":
            return "en-US"
        default:
            return "ja-JP"
    }
}

const filename = date =>
    `${moment(date).format("YYYY-MM")} Load Profile Report`

const dataParser = data => {
    if (!data || !data?.length) return ([])

    let result = data.map(rawFilename => {
        const [, startDate, endDate] = rawFilename.split(/[_.]/g)

        return ({
            endDate,
            error: null,
            filename: filename(startDate),
            loading: false,
            rawFilename,
            startDate
        })
    })

    return result
}

const dateLocale = (lang, dateString) => {
    const
        date = moment(dateString)._d,
        langTag = getLangTag(lang)

    let result
        = new Intl.DateTimeFormat(langTag, { dateStyle: "long" }).format(date)

    if (lang !== "en") return result

    return result.replace(", ", ",")
}

const dateRange = (lang, start, end) =>
    `${dateLocale(lang, start)} - ${dateLocale(lang, end)}`

const BASE_URL = "rakuten/lp-download/regular"

export default function DownloadPastLoadProfile(props) {
    const
        [list, setList] = useState([]),
        [listError, setListError] = useState(null),
        [listFetching, setListFetching] = useState(false)

    useEffect(() => {
        const headers = { headers: { Authorization: props.userData.authToken } }

        setListFetching(true)
        Axios.get(`${window.backendURL}${BASE_URL}`, headers)
            .then(response => {
                if (!response.data) return
                setList(dataParser(response.data))
            })
            .catch(error => setListError(error))
            .then(() => setListFetching(false))
    }, [props.userData.authToken])

    const download = row => {
        const { rawFilename } = row

        if (!rawFilename) return

        const config = {
            headers: { Authorization: props.userData.authToken },
            method: "GET",
            responseType: "blob",
            url: `${window.backendURL}${BASE_URL}/${rawFilename}`
        }

        const match = item => item.rawFilename === rawFilename

        const updateRow = state => setList(list =>
            list.map(item => match(item) ? ({ ...item, ...state }) : item)
        )

        updateRow({ error: null, loading: true })
        Axios(config)
            .then(response => {
                const { data } = response

                if (data.size > 0) {
                    const link = document.createElement("a")

                    link.href = window.URL.createObjectURL(new Blob([data]))
                    link.setAttribute("download", row.filename + ".zip")
                    link.click()
                }
            })
            .catch(error => updateRow({ error }))
            .then(() => updateRow({ loading: false }))
    }

    const
        t = useTranslation(),
        pageT = string => t("downloadPastLoadProfile." + string)

    const lang = getLanguage()

    const columns = [
        {
            name: pageT("loadProfileReport"),
            selector: "filename",
        },
        {
            cell: row => dateRange(lang, row.startDate, row.endDate),
            name: pageT("dateRange"),
            selector: "startDate",
            sortable: true
        },
        {
            cell: row => <>
                {row.loading
                    ? <Spinner className="text-primary" dimension="20px" />
                    : <button
                        className="btn btn-link btn-sm p-0"
                        onClick={() => download(row)}
                    >
                        {pageT("download")}
                    </button>}
                <ErrorMessage className="mt-2 text-danger" data={row.error} />
            </>,
            name: pageT("downloadReport")
        }
    ]

    return (
        <div className="container-fluid">
            <ol className="breadcrumb mb-4">
                <li>{t("system.meterManageMenu")}</li>
                <li className="active">{t("system.downloadPastLoadProfile")}</li>
            </ol>
            <div className="clearfix" />
            <ErrorMessage
                className="alert alert-danger mb-4 text-center"
                data={listError}
            />
            <DataTable
                columns={columns}
                data={list}
                defaultSortAsc={false}
                defaultSortField="startDate"
                key="startDate"
                noHeader
                progressPending={listFetching}
            />
        </div>
    )
}