import React, { Component } from 'react';
import MenuItems from '../../components/MenuItems/MenuItems';

class Sidebar extends Component{
    constructor(props){
        super(props);
        this.state = {
            nowMenuId: false
        };
    }
    

    render(){
        const {role, translation} = this.props
        
        return <div className="fixed-sidebar-left">
            <MenuItems role={role} translation={translation} />
        </div>
    }
}

export default Sidebar;