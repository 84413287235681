import $ from "jquery"
import Axios from "axios"
import DataTable from "react-data-table-component"
import Moment from "react-moment"
import moment from "moment"
import React, { Component } from "react"
import "selectize"
import "selectize/dist/css/selectize.css"

import ErrorMessage from "../../../ErrorMessage"
import MeterConnectionStatus from "../../../MeterConnectionStatus"
import Spinner from "../../../Spinner"
import "./Meter.css"

import { parseMeterList } from "../../../Utils"

class Meter extends Component {
    constructor(props) {
        super(props)

        this.apiUrl = window.backendURL + "meters"
        this.config = { headers: { Authorization: props.userData.authToken } }
        this.state = {
            endDate: moment().format("YYYY-MM-DD"),
            isSearched: false,
            lastMonth: moment().subtract(1, 'months').format("YYYY-MM"),
            loading: false,
            meters: [],
            monthError: null,
            monthDownloading: false,
            selectedMeters: [],
            specificDownloading: false,
            specificError: null,
            startDate: moment().subtract(1, "months").format("YYYY-MM-DD"),
            tableList: []
        }
        this.selectize = null
    }

    componentDidMount() {
        this.getData()

        const dateT = string => this.props.translation("calendar." + string)

        const script = document.createElement("script")

        script.innerHTML = `
            const
                toZero = momentTime => momentTime.set({
                    hour:0, minute:0, second:0, millisecond:0
                }),
                today = toZero(moment()),
                oneMonthAfter = startDate => {
                    let maxDate = startDate.date() === 1
                                    ? startDate.endOf("month")
                                    : startDate.add(1, "months")

                    if (maxDate > today) {
                        maxDate = today
                    }

                    return maxDate
                }

            $("#endDate, #startDate").bootstrapMaterialDatePicker({
                cancelText: "${dateT("cancelText")}",
                lang: "${dateT("lang")}",
                maxDate: "${this.state.endDate}",
                nowButton: true,
                nowText: "${dateT("nowText")}",
                okText: "${dateT("okText")}",
                time: false
            })
            $("#startDate").bootstrapMaterialDatePicker(
                "setDate", "${this.state.startDate}"
            )
            $("#startDate").on("change", function(e, date) {
                const
                    endDate = moment($("#endDate").val()),
                    value = $(this).val(),
                    startDate = moment(value),
                    maxDate = oneMonthAfter(startDate),
                    maxDateText = maxDate.format("YYYY-MM-DD")

                $("#endDate").bootstrapMaterialDatePicker("setMinDate", value)
                $("#endDate").bootstrapMaterialDatePicker(
                    "setMaxDate", maxDateText
                )
                setDateState($(this).val(), "startDate")

                if (endDate > maxDate || endDate < startDate) {
                    $("#endDate").val(maxDateText)
                    setDateState(maxDateText, "endDate")
                }
            })
            $("#endDate").bootstrapMaterialDatePicker(
                "setDate", "${this.state.endDate}"
            )
            $("#endDate").on("change", function(e, date) {
                const
                    value = $(this).val(),
                    endDate = toZero(moment(value)),
                    startDate = toZero(moment($("#startDate").val())),
                    maxDate = oneMonthAfter(startDate),
                    setDate = endDate > maxDate ? maxDate : endDate,
                    setDateText = setDate.format("YYYY-MM-DD")

                $(this).val(setDateText)
                setDateState(setDateText, "endDate")
            })
        `
        window.setDateState = this.setDateState

        document.body.appendChild(script)

        const that = this

        this.selectize = $("#meterId").selectize({
            delimiter: ",",
            plugins: ["remove_button"],
            persist: false,
            create: function (input) {
                return {
                    value: input,
                    text: input,
                }
            },
            addPrecedence: false,
        })
            .on("change", function () {
                that.setState({ meters: this.selectize.getValue().split(",") })
            })
    }

    setDateState = (date, id) => this.setState({ [id]: date })

    getData = () => {
        this.setState({ loading: true })

        const
            { apiUrl, config } = this,
            { isSearched, meters } = this.state

        let data = {}

        if (isSearched && meters.length > 0) {
            data.ids = meters
        }

        Axios.post(apiUrl, data, config)
            .then(response => {
                if (!response.data?.meters) return
                const tableList = parseMeterList(response.data.meters)
                this.setState({ tableList })
            })
            .catch(error => console.error(error.response || error))
            .then(() => this.setState({ loading: false }))
    }

    search = async () => {
        await this.setState({ isSearched: true })
        this.getData()
    }

    clearSearch = async () => {
        this.selectize[0].selectize.clear()
        await this.setState({
            isSearched: false,
            meters: []
        })
        this.getData()
    }

    lpExport = (config, onBefore, onComplete, onError, onSuccess) => {
        const
            { headers } = this.config,
            url = window.backendURL + "rakuten/lp-export"

        onBefore()
        Axios({ ...config, headers, method: "POST", url })
            .then(response => onSuccess(response))
            .catch(error => onError(error))
            .then(() => onComplete())
    }

    getMonthlyReport = () => {
        const config = {
            data: {
                email: true,
                endDt: moment().subtract(1, "M").endOf("M").add(1, "s"),
                meterIDs: [],
                startDt: moment().subtract(1, "M").startOf("M")
            }
        }

        const onBefore = () => this.setState({
            monthDownloading: true,
            monthError: null
        })

        const onComplete = () => this.setState({ monthDownloading: false })

        const onError = error => {
            if (error.response?.status === 403) {
                alert(this.props.translation("export.busy"))
                return
            }

            this.setState({ monthError: error })
        }

        const onSuccess = () =>
            alert(this.props.translation("export.submittedMsg"))

        this.lpExport(config, onBefore, onComplete, onError, onSuccess)
    }

    getSpecificMetersReport = () => {
        const { endDate, selectedMeters, startDate } = this.state

        const config = {
            data: {
                endDt: moment(endDate).add(1, "d").toISOString(),
                meterIDs: selectedMeters,
                startDt: moment(startDate).toISOString()
            },
            responseType: "blob"
        }

        const onBefore = () => this.setState({
            specificDownloading: true,
            specificError: null
        })

        const onComplete = () => this.setState({ specificDownloading: false })

        const onError = error => this.setState({ specificError: error })

        const onSuccess = response => {
            if (response.data.size === 0) return

            const filename = selectedMeters[0]
                + (selectedMeters.length > 1 ? "_and_more" : "")
                + `_${startDate}_${endDate}.zip`

            const link = document.createElement("a")

            link.href = window.URL.createObjectURL(new Blob([response.data]))

            link.setAttribute("download", filename)
            link.click()
        }

        this.lpExport(config, onBefore, onComplete, onError, onSuccess)
    }

    updateSelectMeters = meterId => {
        let meters = this.state.selectedMeters

        if (meters.includes(meterId)) {
            meters.splice(meters.indexOf(meterId), 1)
        }
        else {
            meters.push(meterId)
        }

        this.setState({ selectedMeters: meters })
    }

    selectAll = e => {
        const { tableList } = this.state

        let { selectedMeters } = this.state

        if (e.target.checked) {
            tableList.forEach(item => {
                if (!selectedMeters.includes(item.ID)) {
                    selectedMeters.push(item.ID)
                }
            })
        }
        else {
            tableList.forEach(item => {
                if (selectedMeters.includes(item.ID)) {
                    selectedMeters.splice(selectedMeters.indexOf(item.ID), 1)
                }
            })
        }

        this.setState({ selectedMeters })
    }
    componentDidUpdate() {
        if (!this.selectize) return

        this.selectize[0].selectize.settings.placeholder = this.props.translation("meter.search")
        this.selectize[0].selectize.updatePlaceholder()
    }

    render() {
        const
            { isSearched, selectedMeters, tableList } = this.state,
            meterList = selectedMeters.map((meter, i) =>
                <div className="badge mr-3 mb-3" key={"meter-" + i}>
                    {meter}
                    <i
                        className="material-icons"
                        onClick={
                            this.updateSelectMeters.bind(
                                this, meter
                            )
                        }>clear</i>
                </div>),
            allChecked = tableList
                .filter(item => !selectedMeters.includes(item.ID))
                .length === 0,
            spinner = <Spinner fontSize="14px" dimension="1rem" />,
            t = this.props.translation

        const tableColumns = [
            {
                cell: row =>
                    <input
                        checked={selectedMeters.includes(row.ID)}
                        data-id={row.ID}
                        onChange={this.updateSelectMeters.bind(this, row.ID)}
                        type="checkbox" />,
                center: true,
                grow: .5,
                name: <label className="cursor-pointer">
                    <input
                        className="ml-2"
                        checked={allChecked}
                        onChange={this.selectAll}
                        type="checkbox" />
                    {t("meter.select")}
                </label>
            },
            { name: t("meter.meterId"), selector: "ID", sortable: true },
            { name: t("meter.fanId"), selector: "ParentID", sortable: true },
            {
                cell: row => <MeterConnectionStatus lastTime={row.lastTime} />,
                center: true,
                name: t("meter.meterStatus"),
                selector: "lastTime",
                sortable: true
            },
            {
                cell: row => row.LastActive
                    ? <Moment format="YYYY/MM/DD HH:mm:ss">
                        {row.LastActive}
                    </Moment>
                    : "-",
                center: true,
                grow: 2,
                name: t("meter.receiveTime"),
                selector: "LastActive",
                sortable: true
            },
            {
                cell: row =>
                    <a href={"/RakutenManagement/LoadProfile?meterId=" + row.ID}>
                        <i className="material-icons txt-red">download</i>
                    </a>,
                center: true,
                name: this.props.translation("meter.loadProfile")
            },
            {
                name: this.props.translation("meter.detail"),
                center: true,
                sortable: false,
                cell: row => <a href={"/RakutenManagement/Meter/Detail/" + row.ID}><i className="material-icons txt-red">info</i></a>
            }
        ]

        return (
            <div className="container-fluid">
                <ol className="breadcrumb">
                    <li>{t("system.meterManageMenu")}</li>
                    <li className="active">
                        {t("system.meterManagement")}
                    </li>
                </ol>
                <div className="clearfix" />
                <div className="align-items-center d-flex justify-content-end
                                mb-3">
                    <ErrorMessage
                        className="d-inline-block mr-2 text-danger text-right"
                        data={this.state.monthError} />
                    <button
                        className="btn btn-primary btn-outline"
                        disabled={this.state.monthDownloading}
                        onClick={this.getMonthlyReport}
                    >
                        {this.state.monthDownloading
                            ? spinner
                            : this.state.lastMonth + " " + t("meter.downloadMonthlyBtn")}
                    </button>
                </div>
                <hr className="my-4" />
                <div className="searchBar mt-4">
                    <label htmlFor="meterId">{t("meter.meterId")}</label>
                    <input
                        id="meterId"
                        placeholder={t("meter.search")}
                        type="text" />
                    <button
                        className="btn btn-primary"
                        disabled={this.state.meters.length === 0}
                        onClick={this.search}>
                        <i className="material-icons">search</i>
                    </button>
                </div>
                {isSearched && !this.state.loading
                    ? <div className="text-right">
                        <button
                            className="btn btn-primary mt-3"
                            onClick={this.clearSearch}>
                            <i className="material-icons mr-2">clear_all</i>
                            {t("meter.clearSearchResult")}
                        </button>
                    </div>
                    : null}
                <DataTable
                    columns={tableColumns}
                    data={this.state.tableList}
                    defaultSortAsc={false}
                    defaultSortField="LastActive"
                    noHeader={true}
                    pagination={true}
                    progressPending={this.state.loading} />
                <div className="panel panel-table card-view mt-4">
                    <div className="panel-heading border-bottom mb-2 pb-2">
                        <h4 className="panel-title">
                            {t("meter.selectedMeter")}
                        </h4>
                    </div>
                    <div className="panel-body">
                        <div className="form-inline">
                            <div className="input-group mr-sm-2">
                                <div className="input-group-prepend">
                                    <div className="input-group-text">
                                        {t("system.chooseStartDate")}
                                    </div>
                                </div>
                                <input
                                    className="form-control"
                                    id="startDate"
                                    type="text" />
                            </div>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <div className="input-group-text">
                                        {t("system.chooseEndDate")}
                                    </div>
                                </div>
                                <input
                                    className="form-control"
                                    id="endDate"
                                    type="text" />
                            </div>
                        </div>
                        <hr className="my-3" />
                        <div className="selectedMeters">
                            {selectedMeters.length > 0
                                ? <>
                                    {meterList}
                                    <hr className="mb-3 mt-0" />
                                    <button
                                        className="btn btn-outline btn-primary"
                                        disabled={this.state.specificDownloading}
                                        onClick={this.getSpecificMetersReport}>
                                        {this.state.specificDownloading
                                            ? spinner
                                            : t("meter.downloadLoadProfile")}
                                    </button>
                                </>
                                : t("meter.noSelectedMeter")}
                        </div>
                        <ErrorMessage
                            className="alert alert-danger mb-0 mt-3 text-center"
                            data={this.state.specificError} />
                    </div>
                </div>
            </div>
        )
    }
}

export default Meter